<template xmlns:v-on="">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 l10 offset-l1">
            <div class="card col s12 vert-offset-top-1 padding-top-half">
                <div class="col s12 card-title left-align padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black white-text homeboy-font align-middle">
                    <div class="col s12 no-padding ">
                        Confirm Your Business/Organisation's Email Address(2/3)
                    </div>
                </div>

                <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">

                    <div class="col s12 no-padding">
                        <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-half">
                            <div class="col s12 padding-bottom-half padding-left-0 padding-right-0">
                                A One-Time Password was sent to your registered email.
                            </div>
                            <div class="col s12 no-padding">
                                <input-component id="otp" type="text" v-model="otp" label="Enter your One-Time Password here" required="required" autocomplete="off" data-length="5"></input-component>
                            </div>
                        </div>

                        <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-half">
                            <buttons-component v-on:validOTP="validateConfirmOtp"></buttons-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import InputComponent from "@/components/materialize/InputComponent.vue"
    import http from "../../../http-common"
    import { bus } from "../../../main"
    import {mapState} from "vuex"
    import ButtonsComponent from "@/components/form/ButtonsComponent.vue"

    export default {
        name: "FormTwo",
        components:{
            ButtonsComponent,InputComponent
        },
        data(){

            return{

                otp:"",

            }
        },
        created(){

            let $this = this;
            bus.$off('confirmAction');

            bus.$on('confirmAction', (_data) => {
                if (_data == "confirmOtp") {

                    $this.confirmOtp();
                }
            });

        },
        methods:
            {
                validateConfirmOtp: function()
                {
                    if(this.validateForm())
                    {
                        this.$store.dispatch("SET_ACTION", "confirmOtp");
                    }
                },
                validateForm: function()
                {
                    let _arrErrors = new Array();
                    if(typeof this.otp === "undefined" || this.otp === null || this.otp.length < 6)
                    {
                        _arrErrors.push("Invalid OTP");
                    }
                    else if(this.otp.length > 12)
                    {
                        _arrErrors.push("Invalid OTP");
                    }

                    if(_arrErrors.length > 0)
                    {
                        this.$store.dispatch('GET_MESSAGES',_arrErrors);
                        return false;
                    }

                    return true;
                },
                confirmOtp:function ()
                {
                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: 'registration/confirm/business/otp',
                            data:{
                                email:this.email,
                                otp: this.otp,
                            },
                            method: 'POST', timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$router.push('/register/supplier/existing/third-step');


                            resolve(resp)
                        })
                            .catch(response => {

                                console.log("Response Error: "+JSON.stringify(response));
                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })
                },

            },
        computed:{
             ...mapState({
             email:state=>state.registration.business_email,
             //organisations:state=>state.organisations.organisations
             }),

        },


    };
</script>