/**
 * Created by SDKDEV1 on 8/10/2020.
 */

import http from "../../http-common";
export default {
  state: {
    organisations: [],
    organisation: {}
  },

  getters: {},

  mutations: {
    SET_ORGANISATION(state, organisation) {
      // update subscriptions
      state.organisation = organisation;
    },
    SET_ORGANISATIONS_LIST(state, organisations) {
      // update subscriptions
      state.organisations = organisations;
    }
  },

  actions: {
    GET_ORGANISATIONS_REQUEST: ({ commit }) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/organisations/list",
          method: "POST",
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            //console.log("Subscriptions: " + JSON.stringify(resp.data.data));
            commit("SET_ORGANISATIONS_LIST", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    },

    GET_ORGANISATION_REQUEST: ({ commit }, organisation) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/organisations/view",
          method: "POST",
          data: {
            organisation: organisation
          },
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            // console.log("Supplier: " + JSON.stringify(resp.data.data));
            commit("SET_ORGANISATION", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    }
  }
};
