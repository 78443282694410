<template>
  <div class="row">

    <div class="container">
    <div class="section">

      <div class="row">
        <div class="col s12 no-padding">
          <div class="col s12 center no-padding">
            <p class="left-align light font-14">
              A citizen centric entrepreneurship platform that is designed to encourage locals to buy from local businesses.
            </p>

            <div class="card col s12 homeboy-charcoal-black-text">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="homeboy-font homeboy-charcoal-black-text left-align light">What is the purpose of HomeBoy (Why HomeBoy)</h5>

                  <p class="left-align light">
                    To provide Batswana that have a desire to support local businesses with a structured and direct process that helps them to identify the products they seek near them, assess their suitability, transact and then get rewarded for it.
                  </p>
                  <p class="left-align light">
                    Secondly: to provide local businesses with a platform that will help them to formalize and grow their businesses with every transaction (through ease of identification, improved record keeping and consolidated marketing efforts)
                  </p>
                </div>
              </div>
            </div>

            <div class="card col s12 ">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="homeboy-font homeboy-charcoal-black-text left-align light">What are the main Objectives of HomeBoy</h5>

                  <p class="left-align light">
                    1. Foster behavior change that supports local among buyers and sellers.
                    <br/>
                    2. Create a formalized market access platform for local businesses
                    <br/>
                    3. Create Awareness and visibility of local products and services
                    <br/>
                    4. Consolidate and lower the cost of advertising efforts for local SMEs
                    <br/>
                    5. Create a formalized mechanism that promotes the retention and circulation of money within the local economy.
                  </p>
                </div>
              </div>
            </div>



            <div class="card col s12 homeboy-charcoal-black-text">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="homeboy-font homeboy-charcoal-black-text left-align light">What are the benefits of joining HomeBoy to a business Owner?</h5>

                  <p class="left-align light">

                    1. Improved market access as a result of increased visibility<br/>
                  2. Become part of a consolidated marketing and business development campaign<br/>
                    3. Improve customer retention and sales through the nationwide HomeBoy loyalty program<br/>
                    4. Improve your rate of sale through the HomeBoy Finder<br/>
                    5. Improve customer retention and sales through the nationwide HomeBoy loyalty program<br/>
                    6. Improve your rate of sale through the HomeBoy Finder<br/>
                    7. Improve your sales record keeping and access them via the platform as and when needed<br/>
                    8. Lower advertising costs through the HomeBoy Finder service <br/>
                    9. Improve your sales record keeping and access them via the platform as and when needed<br/>
                    10. Benefit from other third party negotiated offers that are only available to HomeBoys eg. Insurance offers<br/>
                    11. Become part of an ecosystem that ensures that money is retained within the local economy to keep you going
                    </p>
                </div>
              </div>
            </div>

            <div class="card col s12">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="homeboy-font homeboy-charcoal-black-text left-align light">What are the benefits to the buyer?</h5>

                  <p class="left-align light">
                    1. Enjoy discounts through the HomeBoy card-less loyalty program<br/>
                    2. Direct access to buyers via the Find- A- HomeBoy near me service<br/>
                    3. Informed buying decision process through the inbuilt HomeBoy review service<br/>
                    4. Develop a Top Empowerment individual profile that gets you negotiated discounts with partner services outside the HomeBoy network
                  </p>
                </div>
              </div>
            </div>

            <div class="card col s12 homeboy-charcoal-black-text">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="center homeboy-font homeboy-charcoal-black-text">How do I become a HomeBoy (How to Join As a Business Owner)</h5>
                  <p class="left-align light">
                    1. Join via social media platforms @HomeBoybw on Facebook or Instagram (Also applies to a customer)<br/>
                    2. Join via the secure website at www.homeboybw.com   (Also applies to a customer)
                  </p>
                </div>
              </div>
            </div>

            <div class="card col s12">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="homeboy-font homeboy-charcoal-black-text left-align light">What do I need to join?</h5>

                  <p class="left-align light">
                    1. Have a product or service to sell<br/>
                    2. Internet or data connection on your phone or computer<br/>
                    3. Contact details eg. active phone number<br/>
                    4. Location of business (even an informal set up)<br/>
                    5. For increased accuracy Coordinates are preferable but town and ward will also suffice (see how to tell my coordinates)<br/>
                    6. Activate account by paying the required service fee.<br/>
                    7. <span class="font-bold font-italic">Customers only need to register once for free on the platform or on site from a HomeBoy during their first purchase.</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="card col s12 homeboy-charcoal-black-text">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="center homeboy-font homeboy-charcoal-black-text">What happens once I become a HomeBoy (As a business owner)</h5>
                  <p class="left-align light">
                    1. Gain instant access to Cardless Loyalty Program:  This system awards buyers with Empowerment Points (EPs) every time they purchase from system registered SMEs which can be redeemed when they reach specified thresholds. The rewards are in the form of virtual vouchers that can only be used on the same SMEs registered on the platform, ensuring the retention and circulation of money within the local businesses. 
                    <br/>
                    2. Gain instant access to #HomeBoy Finder : This feature allows users who wish to buy local, to find HomeBoys at the click of a button through a google powered product search.
                  </p>
                </div>
              </div>
            </div>

            <div class="card col s12">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="homeboy-font homeboy-charcoal-black-text left-align light">What happens once I join the Homeboy customer network?</h5>

                  <p class="left-align light">
                    1. Gain instant access to the Cardless Loyalty program that earns you Empowerment Points with every transaction.<br/>
                    2. The points will qualify you to receive a minimum discount of 10% from any Homeboy member on your next purchase.<br/>
                    3. The points are accumulated as and when you buy from any Homeboy<br/>
                    4. To activate your redemption process, buy for a minimum total of P300 from a minimum of 3 businesses every month to be eligible.<br/>
                    5. The system runs and generates a ‘Top Empowerment Individuals’ leaderboard that seeks to reward and encourage our most active Homeboy Supporters. Rewards vary from cash, to merchandise and other forms of appreciation.

                  </p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>
  </div>



    <footer class="page-footer homeboy-gold homeboy-charcoal-black-text font-12">
      <div class="container">
        <div class="row">
          <div class="col l6 s12">

          </div>
          <div class="col l3 s12">

          </div>
          <div class="col l3 s12">
            <ul>
              <li>
                <router-link class="homeboy-charcoal-black-text underline"
                             to="/"
                >
                  Home
                </router-link>
              </li>
              <li>
                <router-link class="homeboy-charcoal-black-text underline"
                             to="/privacy-policy"
                >
                  Privacy Policy
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
     <div class="footer-copyright">
        <div class="container">
          Powered by <a class="homeboy-charcoal-black-text underline" href="https://www.sdkdigilab.com">SDK DIGITAL LAB</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

    import M from "materialize-css";
export default {
  name: "ProductInfoComponent",
  props: {
    msg: String
  },
    data()
    {
        return {
            instances: null
        };
    },
    created () {
      this.loadMenu();
    },
    mounted()
    {
        var elems = window.document.querySelectorAll('.parallax');
        this.instances = M.Parallax.init(elems, {});
    },
    methods : {
        loadMenu: function()
        {
            let _arrMenu = [
                {
                    title : "Find Supplier",
                    link: "/search/supplier",
                    icon: "search"
                },


            ];
            this.$store.dispatch('COMMIT_SET_MENU_LINKS', _arrMenu);
        },
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
