/**
 * Created by SDKDEV1 on 8/10/2020.
 */

import http from "../../http-common";
export default {
  state: {
    contracts: [],
    contract: {}
  },

  getters: {},

  mutations: {
    SET_CONTRACT(state, contract) {
      // update subscriptions
      state.contract = contract;
    },
    SET_CONTRACTS_LIST(state, contracts) {
      // update subscriptions
      state.contracts = contracts;
    }
  },

  actions: {
    GET_CONTRACTS_REQUEST: ({ commit }) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/contracts/list",
          method: "POST",
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            //console.log("Subscriptions: " + JSON.stringify(resp.data.data));
            commit("SET_CONTRACTS_LIST", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    },

    GET_CONTRACT_REQUEST: ({ commit }, contract) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/contracts/view",
          method: "POST",
          data: {
            contract: contract
          },
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            // console.log("Supplier: " + JSON.stringify(resp.data.data));
            commit("SET_CONTRACT", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    }
  }
};
