/**
 * Created by SDKDEV1 on 8/10/2020.
 */

import http from "../../http-common";
export default {
  state: {
    packages: [],
    package_: {}
  },

  getters: {},

  mutations: {
    SET_PACKAGE: (state, package_) => {
      state.package_ = package_;
    },
    SET_PACKAGES_LIST: (state, packages) => {
      state.packages = packages;
    }
  },

  actions: {
    GET_PACKAGES_REQUEST: ({ commit }) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/packages/list",
          method: "POST",
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            //console.log("Subscriptions: " + JSON.stringify(resp.data.data));
            commit("SET_PACKAGES_LIST", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    },

    GET_PACKAGE_REQUEST: ({ commit }, package_) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/packages/view",
          method: "POST",
          data: {
            package: package_
          },
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            // console.log("Supplier: " + JSON.stringify(resp.data.data));
            commit("SET_PACKAGE", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    }
  }
};
