<template>
  <div id="myMessageModal" class="modal">
    <div class="modal-content">
      <h4 class="modal-title"><slot></slot></h4>
      <div class="modal-body font-14">
        <ul class="left-align">
          <li v-for="(opt, index) in messages" :key="index">{{ opt }}</li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <a
              href="javascript:void(0);"
              @click="closeModal"
              class="modal-close btn homeboy-gold-text homeboy-charcoal-black modal-close margin-left-half margin-right-half"
      >Close</a
      >
    </div>
  </div>
</template>

<script>
import "materialize-css/dist/js/materialize.min";
import M from "materialize-css";

export default {
  name: "message-modal-component",
  props: {
    messages: {
      type: Array
    }
  },

  /**
   * @description Component local variables
   * @return {Object} data
   * @return {undefined|FormSelect} data.instance
   */
  data() {
    return {
      instance: undefined
    };
  },

  methods: {
    /**
     * @description Initialize a new Materialize select component
     * @param {Object} options
     * @return {FormSelect}
     * @see https://materializecss.com/select.html#options
     */
    initializeModal(options = {}) {
      return M.Modal.init(this.$el, options);
    },
    closeModal() {
      this.instance.close();
    }
  },
  mounted() {
    console.log("component mounted");
    this.instance = this.initializeModal();
    console.log(this.instance);
  },
  watch: {
    messages() {
      console.log("Modal Watcher");
      //this.instance.destroy();

      //this.$nextTick(() => this.instance = this.initializeModal());

      if (this.messages.length > 0) {
        console.log("Messages > 0");
        this.instance.open();
      }
    }
  },

  destroyed() {
    //this.$el.removeEventListener('change', this.changeHandler)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
