import http from "../../http-common";

export default{
    state: {
        userId : null,
        timeout: 30000
    },
    mutations: {
        USER_ID: (state, _value) => {
            state.userId = _value;
        },
    },
    actions: {
        AUTH_LOGIN: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/login',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        console.log("Response:"+JSON.stringify(_response));
                        console.log("user:"+JSON.stringify(_response.data.data.user));
                        const token = _response.data.data.token;
                        localStorage.setItem('token', token) ;// store the token in localstorage

                        http.defaults.headers.common['Authorization'] ="Bearer "+ token;
                        commit('USER_ID', _response.data.data.id);


                        dispatch('SET_AUTH_TOKEN', token);
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch('SET_AUTH_TOKEN');
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        AUTH_LOGOUT: ({commit,dispatch}) => {
            return new Promise((resolve) => {
                http({
                    url: '/logout',
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        localStorage.removeItem('token');
                        // remove the axios default header
                        delete http.defaults.headers.common['Authorization'];
                        dispatch('SET_AUTH_TOKEN');
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            })
        },
    },
};

