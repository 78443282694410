import http from "../../http-common"
export default {
    state:{
        selected_organisation:{},
        list_services:[],
        selected_service: null
    },

    mutations:{

        SET_3DP_ORGANISATION:(state,_organisation_key)=>{
            state.selected_organisation = _organisation_key;
        },
        SET_3DP_SERVICES:(state, _listItems) => {
                    state.list_services = _listItems;
        },
        SET_SELECTED_SERVICE: (state, _value) => {
            state.selected_service = _value;
        }

    },
    actions:{
        GET_3DP_ORGANISATION_REQUEST:({commit},_organisation_key)=>
        {
            commit('START_PROCESSING',{ root: true });

            return new Promise((resolve) => {
                    http({
                             url: 'user/organisations/view',
                             method: 'POST',
                             data:{
                                 organisation:_organisation_key,
                             },
                             timeout: 30000

                         }).then(resp => {
                    commit('STOP_PROCESSING',{ root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            // console.log("Supplier: " + JSON.stringify(resp.data.data));
            commit('SET_3DP_ORGANISATION',resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp)
        })
        .catch(response => {

            console.log("Response Error: "+JSON.stringify(response));
            commit('STOP_PROCESSING',{ root: true });
            commit('SET_MESSAGES',response.data.messages,{ root: true });
        })
        })
        },

        GET_3DP_SERVICES:({commit})=>
        {
            commit('START_PROCESSING',{ root: true });

            return new Promise((resolve) => {
                    http({
                             url: 'services/app/list',
                             method: 'POST',
                             timeout: 30000

                         }).then(resp => {
                    commit('STOP_PROCESSING',{ root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            // console.log("Supplier: " + JSON.stringify(resp.data.data));
            commit('SET_3DP_SERVICES',resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp)
        })
        .catch(response => {

            console.log("Response Error: "+JSON.stringify(response));
            commit('STOP_PROCESSING',{ root: true });
            commit('SET_MESSAGES',response.data.messages,{ root: true });
        })
        })
        },

        COMMIT_SET_SELECTED_SERVICE:({commit}, _value)=>
        {
            commit('SET_SELECTED_SERVICE',_value);
        },
    }
}