<template>
  <div class="home">
    <ProductInfoComponent msg="Welcome To HomeBoy"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ProductInfoComponent from "@/components/ProductInfoComponent.vue";

export default {
  name: "ProductInfo",
  components: {
    ProductInfoComponent
  },
    mounted()
    {
        this.$setPageHeader({
            title: "What is HomeBoy",
            icon: "chevron_left",
            url: "/"
        });
    }

};
</script>
