<template>

    <div class="col s12 no-padding">
        <div class="col s6 m4 l3">
            <a class="btn btn-block homeboy-gold-text homeboy-charcoal-black" @click.prevent="navigatePrev" type="button" v-show="togglePrevious()">Previous</a>
        </div>
        <div class="col s6 m4 offset-m4 l3 offset-l6">
            <a class="btn btn-block homeboy-gold-text homeboy-charcoal-black" @click.prevent="navigateNext" type="button" :disabled="!buttonDisabled">{{ nextBtnTxt()}}</a>
        </div>

    </div>

</template>

<script>
    import {mapState} from "vuex"

    import { bus } from "../../main";

    export default {

        props:{
            buttonDisabled:{
                type:Boolean,
                default:true
            },
            step: {
                type: Number,
                default: 1
            },
            minStep: {
                type: Number,
                default: 1
            },
            maxStep: {
                type: Number,
                default: 1
            },
            useSteps: {
                type: Boolean,
                default: false
            }
        },
        data() {

            return {
                //showFinish:true
            }
        },
        methods: {


            nextBtnTxt() {

                if((!this.useSteps && this.$route.name == 'thirdStep') || (this.useSteps && this.step === this.maxStep))
                {

                    return 'Finish';
                }

                return 'Next';
            },
            togglePrevious() {
                //let isforth =  state=>state.registration.isFourth;

                if(this.useSteps && this.step > this.minStep && this.step <= this.maxStep)
                    return true;

                return this.$route.name != 'register-supplier' && !this.isforth;
            },
            navigateNext() {




                if(!this.useSteps)
                {
                    if(this.$route.name == 'firstStep') {
                        this.$emit("validEmail");
                    } else if(this.$route.name == 'secondStep') {

                        this.$emit("validOTP");


                    }
                    else if(this.$route.name == 'fourthStep')
                    {
                        this.$emit("validOrganisation");
                    }
                    else {

                        this.$store.dispatch('SET_FOURTH_STEP',false);
                        this.$emit("validSupplier");


                        // handle saving of the form
                    }
                }
                else
                {
                    console.log("Clicked Next Step");
                    if(this.step < this.maxStep)
                    {
                        console.log("Emit Next Step");
                        bus.$emit("goToNextStep");
                    }
                    if(this.step === this.maxStep && this.$route.name == 'fourthStep')
                    {
                        this.$emit("validOrganisation");
                    }

                }

            },
            navigatePrev() {

                if(!this.useSteps)
                {
                    if(this.$route.name == 'thirdStep') {

                        console.log("Fourth: "+this.isforth);
                        if(this.isforth)
                        {
                            this.$store.dispatch('SET_FOURTH_STEP',false);
                            //this.$router.push('/register');
                        }
                        else
                        {
                            this.$router.push('/register/existing/second-step');

                        }

                    }
                    else if(this.$route.name == 'secondStep') {

                        this.$router.push('/register/existing');
                    }
                    else if(this.$route.name == 'fourthStep') {

                        this.$router.push('/register');
                    }
                    else if(this.$route.name == 'firstStep') {

                        this.$router.push('/register');
                    }
                }
                else if(this.step > this.minStep)
                {
                    bus.$emit("goToPreviousStep");
                }

            }
        },
        computed: {
            ...mapState({
                isforth: state => state.registration.isFourth,
            }),
        }

    }
</script>