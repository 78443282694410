<template xmlns:v-on="">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 l10 offset-l1">
            <div class="card col s12 vert-offset-top-1 padding-top-half">
                <div class="col s12 card-title left-align padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black white-text homeboy-font align-middle">
                    <div class="col s12 no-padding">
                        Enter Your Existing Business Email(1/3)
                    </div>
                </div>

                <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">

                    <div class="col s12 no-padding">
                        <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-half">
                            <div class="col s12 no-padding">

                                <input-component id="existing_email" type="email" v-model="existing_email" label="E-Mail Address" required="required" autocomplete="off" data-length="150"></input-component>

                            </div>
                            <!--<div class="col s12 no-padding vert-offset-top-half">
                                <div class="col s12 m6 l2">
                                    <button class="btn btn-small yellow darken-2" v-on:click="sendOtpToEmail">
                                        Search
                                    </button>
                                </div>

                            </div>-->
                        </div>

                        <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-half">
                            <buttons-component v-on:validEmail="validateConfirmEmail"></buttons-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import InputComponent from "@/components/materialize/InputComponent.vue"
    import ButtonsComponent from "@/components/form/ButtonsComponent.vue"
    import http from "../../../http-common"
    import { bus } from "../../../main"


    export default {
        name: "FormOne",
        components:{
            ButtonsComponent,InputComponent
        },
        data(){

            return{
                existing_email:"",
            }
        },
        created(){

            let $this = this;
            bus.$off('confirmAction');

            bus.$on('confirmAction', (_data) => {
                if (_data == "confirmEmail") {

                    $this.sendOtpToEmail();
                }
            });

        },
        methods:
            {
                validateConfirmEmail: function()
                {
                    if(this.validateForm())
                    {
                        this.$store.dispatch("SET_ACTION", "confirmEmail");
                    }
                },
                validateForm: function()
                {
                    let _arrErrors = new Array();

                    if(typeof this.existing_email === "undefined" || this.existing_email === null || this.existing_email.length <= 0)
                    {
                        _arrErrors.push("Invalid Email");
                    }
                    else if(this.existing_email.length > 100)
                    {
                        _arrErrors.push("Email too long, cannot exceed 100 characters");
                    }
                    else if(!this.existing_email.isValidateEmail())
                    {
                        _arrErrors.push("Invalid Email");
                    }

                    if(_arrErrors.length > 0)
                    {
                        this.$store.dispatch('GET_MESSAGES',_arrErrors);
                        return false;
                    }

                    return true;
                },
                sendOtpToEmail:function () {
                     console.log("Existing email: "+this.existing_email);
                    if(typeof this.existing_email !== "undefined" || this.existing_email !== null || this.existing_email !=="")
                    {
                        this.$store.dispatch('START_PROCESSING');
                        return new Promise((resolve) => {
                            http({
                                url: 'registration/find/business',
                                data:{
                                    email:this.existing_email
                                },
                                method: 'POST', timeout: 30000
                            }).then(resp => {
                                this.$store.dispatch('STOP_PROCESSING');

                                console.log("Response Find Business: " + JSON.stringify(resp.data.data));
                                /*console.log("data:" + JSON.stringify(resp.data));*/

                                if(typeof resp.data.data !=="undefined" && resp.data.data !== null)
                                {


                                    this.$store.dispatch('SET_REG_ORGANISATION_LIST',resp.data.data);
                                    this.$store.dispatch('SET_BUSINESS_EMAIL',this.existing_email);

                                    this.$router.push('/register/supplier/existing/second-step');
                                }
                                else
                                {
                                    this.$store.dispatch('GET_MESSAGES',["No business is registered with this email"]);
                                }


                                //this.$store.dispatch('SET_COUNTRIES_LIST',resp.data.data);
                                //this.$router.push('/organisation/view');
                                // you have your token, now log in your user :)

                                resolve(resp)
                            })
                                .catch(response => {

                                    console.log("Response Error: "+JSON.stringify(response));
                                    this.$store.dispatch('STOP_PROCESSING');
                                    this.$store.dispatch('GET_MESSAGES',response.data.messages);
                                })
                        })
                    }
                    else
                    {
                        this.$store.dispatch('GET_MESSAGES',["Please enter your business email"]);
                    }
                },
            },



    };
</script>