
import http from "../../http-common";

export default{
    state: {
        list_items              : null,
        paginated_list_items    : null,
        selected_item           : null,
        list_items_active       : null
    },
    mutations: {
        COUNTRY_ADD_PAGINATED_LIST(state, _value)
        {
            if(typeof state.paginated_list_items  !== "undefined" && state.paginated_list_items  !== null)
            {
                if(typeof _value !== "undefined" && _value !== null && _value.length > 0)
                {
                    for(let i=0;i<_value.length;i++)
                        state.paginated_list_items.push(_value[i]);
                }
                else
                {
                    state.paginated_list_items  = null;
                }
            }
            else
            {
                state.paginated_list_items  = _value;
            }
        },
        COUNTRY_SET_LIST(state, _value)
        {
            state.list_items = _value;
        },
        COUNTRY_SET_LIST_ACTIVE(state, _value)
        {
            state.list_items_active = _value;
        },
        COUNTRY_SET_PAGINATED_LIST(state, _value)
        {
            state.paginated_list_items = _value;
        },
        COUNTRY_SET_SELECTED (state,_value)
        {
            state.selected_item = _value;
        }

    },
    actions: {
        COUNTRY_ADD_PAGINATED_LIST : ({commit}, _value) =>
        {
            commit("COUNTRY_ADD_PAGINATED_LIST", _value);
        },
        COUNTRY_SET_LIST : ({commit}, _value) =>
        {
            commit("COUNTRY_SET_LIST", _value);
        },
        COUNTRY_SET_LIST_ACTIVE : ({commit}, _value) =>
        {
            commit("COUNTRY_SET_LIST_ACTIVE", _value);
        },
        COUNTRY_SET_PAGINATED_LIST : ({commit}, _value) =>
        {
            commit("COUNTRY_SET_PAGINATED_LIST", _value);
        },
        COUNTRY_SET_SELECTED : ({commit}, _value) =>
        {
            commit("COUNTRY_SET_SELECTED", _value);
        },

        COUNTRY_GET_LIST: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/countries/list',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        commit("COUNTRY_SET_LIST", _response.data.data);
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        COUNTRY_GET_LIST_ACTIVE: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/countries/list/active',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        commit("COUNTRY_SET_LIST_ACTIVE", _response.data.data);
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        COUNTRY_GET_PAGINATED_LIST: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: 'countries',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof _response.data.data !== "undefined" && _response.data.data !== null)
                        {
                            if(typeof _formData !== "undefined" && _formData !== null && typeof _formData.page !== "undefined" && _formData.page !== null && _formData.page > 1)
                            {
                                commit("COUNTRY_ADD_PAGINATED_LIST", _response.data.data);
                            }
                            else
                            {
                                commit("COUNTRY_SET_PAGINATED_LIST", _response.data.data);
                            }
                        }
                        else
                        {
                            window.console.log("No data received?!!");
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        COUNTRY_GET_SELECTED: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: 'inputs/types/view',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        commit("COUNTRY_SET_SELECTED",_response.data.data);
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        /*COUNTRY_ACTIVATE: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/inputs/types/activate',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        COUNTRY_DEACTIVATE: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: 'inputs/types/deactivate',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(resp => {
                        commit("STOP_PROCESSING", { root: true });
                        resolve(resp);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        COUNTRY_SAVE: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: "inputs/types/save",
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },*/
        COUNTRY_SEARCH: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/inputs/types/search',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
    },
};
