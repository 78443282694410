<template>
  <div id="app" class="font-12">
    <loading-component></loading-component>
    <message-modal-component :messages="messages"></message-modal-component>
    <confirm-modal-component :actionName="actionName"></confirm-modal-component>
    <tool-tip-component></tool-tip-component>

    <nav id="navi">
      <div class="nav-wrapper homeboy-charcoal-black">
        <a v-if="!hasPageHeader" class="left brand-logo " href="/">
          <img src="/homeboy_logo_final_512x512.png" class="responsive-img logo-box-40 align-middle title-logo-align-middle" alt="HomeBoy"/>
          <span class="homeboy-font normal-text">HomeBoy</span>
        </a>
        <span v-else class="left">
          <router-link class="white-text left padding-left-half padding-right-half clickable"
                       :to="page_header_html.url">
            <i class="material-icons">{{ page_header_html.icon }}</i>
          </router-link>
          <span class="homeboy-font homeboy-gold-text font-22">{{ page_header_html.title }}</span>
        </span>
        <a href="#" data-target="slideout" title="Menu" class="right sidenav-trigger white-text show-on-large"><i class="material-icons">menu</i></a>
        <ul id="nav-mobile" class="right">
          <!-- Authentication Links -->

          <li v-if="!authenticated">
            <router-link to="/login" class="white-text" title="Login">
              <i class="material-icons tiny">lock</i>
            </router-link>

          </li>
          <span v-if="isValidMenu">
            <li v-for="(_item, _index) in menu_links" :key="_index">
            <a  @click.prevent="goToUrl(_item.link)" class="white-text" :title="_item.title">
              <span v-if="(typeof _item.icon !== 'undefined' && _item.icon !== null) ">
                <i class="material-icons tiny">{{ _item.icon }}</i>
              </span>
              <span v-else>{{ _item.title }}</span>

            </a>
          </li>
          </span>
          <!--<li v-if="authenticated">
            <router-link  class="white-text" to="/customer/home" title="Services">
              <i class="material-icons tiny">apps</i>
            </router-link>
          </li>-->
          <li v-if="authenticated">
            <a class="white-text" @click.prevent="logout" title="Logout">
              <i class="material-icons tiny">exit_to_app</i>

            </a>
          </li>

        </ul>

      </div>

    </nav>

    <side-nav-component id="slideout">
      <li v-if="!authenticated">
        <router-link  to="/login" class="sidenav-close">
          <i class="material-icons black-text">lock</i>
          Login
        </router-link>
      </li>

        <span v-if="isValidMenu">
            <li v-for="(_item, _index) in menu_links" :key="_index" class="sidenav-close">

            <a  @click="goToUrl(_item.link)">
              {{ _item.title }}
                <i v-if="!_item.icon" class="material-icons black-text">links</i>
                <i v-else class="material-icons black-text" v-html="_item.icon"></i>
            </a>
          </li>
          </span>
      <!--<li v-if="authenticated">
        <router-link to="/customer/home" class="sidenav-close">
          <i class="material-icons black-text">apps</i>
          Services
        </router-link>
      </li>-->
      <li v-if="!authenticated">
        <router-link to="/register/supplier" class="sidenav-close">
          <i class="material-icons black-text">assignment</i>
          Become A HomeBoy
        </router-link>
      </li>
      <li v-if="!authenticated">
        <router-link to="/search/supplier" class="sidenav-close">
          <i class="material-icons black-text">search</i>
          Find A HomeBoy
        </router-link>
      </li>
      <li v-if="!authenticated">
        <router-link to="/register" class="sidenav-close">
          <i class="material-icons black-text">assignment_ind</i>
          Support A HomeBoy
        </router-link>
      </li>
      <li v-if="!authenticated">
        <router-link to="/transact" class="sidenav-close">
          <i class="material-icons black-text">swap_vert</i>
          HomeBoy Transact
        </router-link>
      </li>
      <li v-if="authenticated" class="dropdown-trigger">
        <a @click.prevent="logout" class="sidenav-close">
          <i class="material-icons black-text">exit_to_app</i>
          Logout
        </a>
        <a @click.prevent="customerLogout" class="sidenav-close">
          <i class="material-icons black-text">exit_to_app</i>
          Logout
        </a>
      </li>

    </side-nav-component>

    <router-view></router-view>



  </div>
</template>

<style>
  @import "assets/appsheet.css";
  @import "assets/borders.css";
  @import "assets/loader.css";
  @import "assets/padding.css";
  @import "assets/margin.css";
  @import "assets/password-meter.css";
  @import "assets/style.css";
  @import "assets/vert-offset.css";

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  @font-face {
    font-family: "HomeBoy";
    src: url("./assets/fonts/Boldness.otf");
  }
  @font-face {
    font-family: "Roboto-Bold";
    src: url("./assets/fonts/Roboto-Bold.woff");
  }
  @font-face {
    font-family: "Roboto";
    src: url("./assets/fonts/Roboto-Regular.woff");
  }
  @font-face {
    font-family: "Roboto-Thin";
    src: url("./assets/fonts/Roboto-Thin.woff");
  }
  @font-face {
    font-family: "Roboto-Medium";
    src: url("./assets/fonts/Roboto-Medium.woff");
  }
  @font-face {
    font-family: "Roboto-Light";
    src: url("./assets/fonts/Roboto-Light.woff");
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
</style>

<script>
    import http from "./http-common";
    import store from "./store/index";
    import {mapState} from "vuex";
    import LoadingComponent from "@/components/materialize/LoadingComponent.vue";
    import MessageModalComponent from "@/components/materialize/MessageModalComponent.vue";
    import SideNavComponent from "@/components/materialize/SideNavComponent.vue";
    import ToolTipComponent from "@/components/materialize/ToolTipComponent.vue";
    import ConfirmModalComponent from "@/components/materialize/ConfirmModalComponent.vue";

    export default {
        components:{
            LoadingComponent,MessageModalComponent,SideNavComponent,ConfirmModalComponent, ToolTipComponent
        },
        methods:{

            serviceList: function () {
                this.$router.push('')
            },
            goToUrl : function(_url)
            {
                this.$router.push(_url);
            },
            logout: function () {
                if(!this.isValidMSISDN)
                {
                    this.$store.dispatch('AUTH_LOGOUT')
                        .then(() => {
                            this.$router.push('/')
                        })
                }
                else
                {
                    this.customerLogout();
                }
            },
            customerLogout: function()
            {
                this.$store.dispatch('CUSTOMER_AUTH_LOGOUT')
                    .then(() => {
                        this.$router.push('/')
                    });
            },
            checkCurrentLogin:function ()  {
                console.log("checkCurrentLogin:");
                console.log("token "+store.getters.isAuthenticated);

                let noauthroutes = [
                    "/","/register","/login","/send/otp","/confirm/mobile","/pin/reset",
                    "/pin/reset/request","/search/supplier","/register/supplier",
                    "/register/supplier/existing","/register/supplier/existing/second-step",
                    "/register/supplier/existing/third-step","/register/supplier/new",
                    "/default/register", "/privacy-policy", "/product-info"
                ];

                console.log("Route: "+ this.$route.path);


                if (this.$store.getters.isAuthenticated == false && !noauthroutes.includes(this.$route.path)) {
                    this.$router.push('/')
                }
            },

            checkRoute:function ()
            {
                console.log("checkRoute:");

                http.interceptors.response.use(undefined, function (err) {
                    return new Promise(function () {
                        console.log("Err: "+JSON.stringify(err));
                        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                            // if you ever get an unauthorized, logout the user
                            this.$store.dispatch('AUTH_LOGOUT')
                            // you can also redirect to /login if needed !
                        }
                        throw err;
                    });
                });
            }

        },
        created(){
            this.checkRoute();
            this.checkCurrentLogin();
        },
        updated () {
            this.checkCurrentLogin()
        },
        computed: {
            isValidMenu()
            {
                if(typeof this.menu_links === "undefined" || this.menu_links === null || this.menu_links.length <= 0)
                    return false;

                return true;
            },
            hasPageHeader()
            {
                if(
                    typeof this.page_header_html === "undefined" || this.page_header_html === null ||
                    typeof this.page_header_html.icon === "undefined" || this.page_header_html.icon === null ||
                    typeof this.page_header_html.title === "undefined" || this.page_header_html.title === null ||
                    typeof this.page_header_html.url === "undefined" || this.page_header_html.url === null
                )
                    return false;

                return true;
            },
            authenticated () {
                return this.$store.getters.isAuthenticated
            },

            ...mapState({
                messages: state => state.messages,
                actionName: state => state.actionName,
                organisation: state => state.organisations.organisation,
                menu_links : state => state.menu_links,
                page_header_html : state => state.page_header_html,
                msisdn: state => state.customers.msisdn,
                country: state => state.customers.country,
                min_msisdn: state => state.customers.validation.msisdn.minLength,
                max_msisdn: state => state.customers.validation.msisdn.maxLength,

            }),
            hasMSISDN: function()
            {
                return (typeof this.msisdn !== "undefined" && this.msisdn !== null && this.msisdn.length > 0);
            },
            hasCountryCode: function()
            {
                return (typeof this.country !== "undefined" && this.country !== null);
            },
            isPhoneNumber()
            {
                return this.hasMSISDN && this.msisdn.isPhoneNumber();
            },
            isValidMSISDN: function()
            {
                return (this.hasCountryCode && this.hasMSISDN && this.msisdn.length >= this.min_msisdn && this.msisdn.length <= this.max_msisdn && this.msisdn.isPhoneNumber());
            },
        },
        mounted()
        {
            this.$store.dispatch('STOP_PROCESSING');
            http.interceptors.response.use((response)=>{
                    //console.log("* Response"+JSON.stringify(response));
                    if(response.data.success == false)
                    {
                        return Promise.reject(response);
                    }
                    return response
                },
                (error)=>{

                    var response = {
                        data: {
                            messages : [error.message]
                        },
                        message: error.message,
                    };

                    console.log("* Error Response"+JSON.stringify(error));

                    return Promise.reject(response);
                }
            );
        }
    }
</script>

