import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import store from "./store";
import http from "./http-common";
import "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
/*import moment from "./assets/js/moment.js";*/

Vue.config.productionTip = false;
/*Vue.prototype.moment = moment;*/
/*document.addEventListener('contextmenu', event => event.preventDefault());*/


const token = localStorage.getItem("token");
if (typeof token !== "undefined" && token !== null && token.length > 0)
{
    http.defaults.headers.common["Authorization"] = "Bearer "+token;
}
Vue.prototype.$accessToken = token;
Vue.prototype.$source = "HomeBoy";
Vue.prototype.$baseUrl = "https://api.brandboxbw.com/";
Vue.prototype.$localUrl = "https://portal.brandboxbw.com/";

Vue.prototype.$setPageHeader = function(_content)
{
    this.$store.dispatch('COMMIT_SET_PAGE_HEADER_HTML', _content);
};
Vue.prototype.$clearPageHeader = function()
{
    this.$store.dispatch('COMMIT_SET_PAGE_HEADER_HTML', null);
};

Vue.prototype.$dataURIToBlob = function( dataURI )
{
    let byteString = atob( dataURI.split( ',' )[ 1 ] );
    let mimeString = dataURI.split( ',' )[ 0 ].split( ':' )[ 1 ].split( ';' )[ 0 ];

    let buffer = new ArrayBuffer( byteString.length );
    let data = new DataView( buffer );

    for( let i = 0; i < byteString.length; i++ ) {
        data.setUint8( i, byteString.charCodeAt( i ) );
    }

    return new Blob( [ buffer ], { type: mimeString } );
};
String.prototype.isNumeric = function() {
    return !isNaN(parseFloat(this)) && isFinite(this);
};
Vue.prototype.$isValidLatitude = function(_value) {
    return !isNaN(parseFloat(_value)) && isFinite(_value) && parseFloat(_value)>=-90 && parseFloat(_value)<=90;
};
Vue.prototype.$isValidLongitude = function(_value) {
    return !isNaN(parseFloat(_value)) && isFinite(_value) && parseFloat(_value)>=-180 && parseFloat(_value)<=180;
};

String.prototype.startsWith || (String.prototype.startsWith = function(word) {
    return this.lastIndexOf(word, 0) === 0;
});
String.prototype.includes || (String.prototype.includes = function(word) {
    return this.indexOf(word) >= 0;
});
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.isValidateEmail = function ()
{
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this);
};

String.prototype.endsWith || (String.prototype.endsWith = function(word)
{
    return this.indexOf(word, this.length - word.length) !== -1;
});

String.prototype.isNumber = function(){return /^[0-9]+$/.test(this);};
String.prototype.isPhoneNumber = function()
{
    return true;
    /*let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return this.match(phoneno);*/
};

Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
};
Date.prototype.addDays = function(d) {
    this.setTime(this.getTime() + (d*24*60*60*1000));
    return this;
};

/*Gaborone CBD * -24.6448953,25.9052055 */
Vue.prototype.$defaultGeoCenter = {
    lat: parseFloat(-24.6448953),
    lng: parseFloat(25.9052055),
};

Date.daysBetween = function( date1, date2 )
{
  /*//Get 1 day in milliseconds*/
  /*//var one_day=1000*60*60*24;*/

  /*// Convert both dates to milliseconds*/
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();

  /*// Calculate the difference in milliseconds*/
    var difference_ms = date2_ms - date1_ms;
  /*//take out milliseconds*/
    difference_ms = difference_ms/1000;
    var seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms/60;
    var minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms/60;
    var hours = Math.floor(difference_ms % 24);
    var days = Math.floor(difference_ms/24);

    return days + ' days, ' + hours + ' hours, ' + minutes + ' minutes, and ' + seconds + ' seconds';
};


export const bus = new Vue();


new Vue({
    router,
    store,
    data: {},
    token: "",
    render: h => h(App),
}).$mount('#app');
