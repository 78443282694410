<template>
  <div class="row">
    <!--<div id="index-banner" class="parallax-container">
      <div class="section no-pad-bot">
        <div class="container">

          &lt;!&ndash;<span class="homeboy-font white-text">
            <span class="font-50">H</span><span class="font-40">ome</span><span class="font-50">B</span><span class="font-40">oy</span>
          </span>&ndash;&gt;

          <div class="row vert-offset-bottom-0">
            <h5 class="header col s12 light uppercase-text font-bold vert-offset-top-3 font-roboto-bold">
              <span class="white-text">Serving you the best</span>
            <br/>
              <span class="homeboy-charcoal-black-text">of Local from <span class="white-text">your Screen</span></span>
            </h5>
          </div>
          <div class="row vert-offset-top-half">
            <router-link
                    class="btn-large homeboy-charcoal-black-text white waves-effect waves-light font-roboto-bold"
                    to="/search/supplier">
              FIND A HOMEBOY
            </router-link>
            </div>
          <br><br>

        </div>
      </div>
      <div class="parallax"><img src="home/bg-6.jpg" alt="Unsplashed background img 1"></div>
    </div>-->

    <div class="container">
      <div class="section">

        <div class="row">
          <div class="col s12 center">
            <h4 class="homeboy-font homeboy-charcoal-black-text">About HomeBoy</h4>
            <p class="left-align light">
              Homeboy is a citizen centric entrepreneurship platform that is designed to encourage locals to buy from local businesses.
              We aim to provide Batswana that have a desire to support local businesses with a structured and direct process that helps them to identify the products they seek near them, assess their suitability, transact and then get rewarded for it.
              Secondly: we aim to provide local businesses with a platform that will help them to formalize and grow their businesses with every transaction (through ease of identification, improved record keeping and consolidated marketing efforts)
            </p>
            <div class="col s12 center">
              <router-link
                      class="btn waves-effect waves-light btn-block white homeboy-charcoal-black-text font-roboto-bold"
                      to="/product-info"
              >
                LEARN MORE
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="container">
      <div class="section">

        <!--   Icon Section   -->
        <div class="row">
          <div class="card col s12 m4 homeboy-charcoal-black white-text">
            <div class="col s12 no-padding">
              <div class="icon-block">
                <h5 class="center homeboy-font homeboy-gold-text">Become a HomeBoy</h5>

                <p class="light font-12">
                  Get discovered by buyers via the Find-A-Homeboy near me service.Gain instant access to our Loyalty Program and improve sales and customer retention.
                </p>
              </div>
            </div>
            <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-1 no-padding">
              <router-link
                      class="btn waves-effect waves-light btn-block white homeboy-charcoal-black-text font-roboto-bold"
                      to="/register/supplier">
                REGISTER/TRANSACT NOW
              </router-link>
            </div>
          </div>

          <div class="card col s12 m4 homeboy-gold black-text">
            <div class="col s12 no-padding">
              <div class="icon-block">
                <h5 class="center homeboy-font">Find a HomeBoy</h5>

                <p class="light font-12">
                  Gain instant access to #HOMEBOY Finder : This feature allows users who wish to buy local, to find Homeboys at the click of a button.
                </p>
              </div>
            </div>
            <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-1 no-padding ">
              <router-link
                      class="btn waves-effect waves-light btn-block white homeboy-charcoal-black-text font-roboto-bold"
                      to="/search/supplier">
                FIND A HOMEBOY
              </router-link>
            </div>
          </div>

          <div class="card col s12 m4 homeboy-charcoal-black white-text">
            <div class="col s12 no-padding">
              <div class="icon-block">
                <h5 class="center homeboy-font homeboy-gold-text">Support a HomeBoy</h5>

                <p class="light font-12">
                  Enjoy discounts through the Homeboy card-less loyalty program.
                  Gain direct access to sellers via the Find-A-Homeboy near me service.
                </p>
              </div>
            </div>
            <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-1 no-padding">
              <router-link
                      class="btn waves-effect waves-light btn-block white homeboy-charcoal-black-text font-roboto-bold"
                      to="/register">
                REGISTER/LOGIN NOW
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </div>



    <!--<div class="container">
    <div class="section">

      <div class="row">
        <div class="col s12 center">
          <h4 class="homeboy-font homeboy-charcoal-black-text">About HomeBoy</h4>
          <p class="left-align light">
            Homeboy is a citizen centric entrepreneurship platform that is designed to encourage locals to buy from local businesses.
            We aim to provide Batswana that have a desire to support local businesses with a structured and direct process that helps them to identify the products they seek near them, assess their suitability, transact and then get rewarded for it.
            Secondly: we aim to provide local businesses with a platform that will help them to formalize and grow their businesses with every transaction (through ease of identification, improved record keeping and consolidated marketing efforts)
          </p>
          <div class="col s12 center">
            <router-link
                    class="btn waves-effect waves-light btn-block white homeboy-charcoal-black-text font-roboto-bold"
                         to="/product-info"
            >
              LEARN MORE
            </router-link>
          </div>
        </div>
      </div>

    </div>
  </div>-->
    <div class="parallax-container valign-wrapper ">
      <div class="section no-pad-bot">
        <div class="container">
          <div class="row center">
            <h5 class="header col s12 light  homeboy-charcoal-black-text">Already a HomeBoy? Do your transactions here</h5>
          </div>
          <div class="row center">
            <router-link to="/transact" class="btn waves-effect waves-light font-roboto-bold homeboy-charcoal-black white-text font-16">
              Click Here To Transact
            </router-link>
          </div>
        </div>
      </div>
      <div class="parallax homeboy-gold"></div>
    </div>

    <div class="container">
      <div class="section">

        <div class="row">
          <div class="col s12">
            <h4 class="homeboy-font homeboy-charcoal-black-text">Contact Us</h4>
            <div class="col s12">
              <div class="col s12 m10 offset-m1 l8 offset-l2 no-padding">
                <div class="col s4 font-bold">Physical Address</div>
                <div class="col s8 left-align">
                  Plot 75783,Setlhoa Office Park,Gaborone
                </div>
              </div>
              <div class="col s12 m10 offset-m1 l8 offset-l2 no-padding">
                <div class="col s4 font-bold">
                  Email
                </div>
                <div class="col s8 left-align">admin@homeboybw.com</div>
              </div>
            </div>

          </div>
          <div class="col s12 center">
            <p class="center font-bold">Connect With Us</p>
            <div class="col s4 m4 l4 right-align light">
              <a href="https://www.facebook.com/homeboybw" target="_blank"><img src="../assets/img/facebook_w_icon.png" width="48" alt="Facebook"></a>
            </div>
            <div class="col s4 m4 l4 center light">
              <a href="https://instagram.com/homeboybw" target="_blank"><img src="../assets/img/insta_w_icon.png" width="48" alt="Instagram"></a>
            </div>
            <div class="col s4 m4 l4 left-align light">
              <a href="https://twitter.com/homeboybw" target="_blank"><img src="../assets/img/twitter_w_icon.png" width="48" alt="Twitter"></a>
            </div>
          </div>
        </div>

      </div>
    </div>
   <!-- <div class="parallax-container valign-wrapper">
      <div class="section no-pad-bot">
        <div class="container">
          <div class="row center">
            <h5 class="header col s12 light">A modern responsive front-end framework based on Material Design</h5>
          </div>
        </div>
      </div>
      <div class="parallax"><img src="home/background3.jpg" alt="Unsplashed background img 3"></div>
    </div>
-->
    <footer class="page-footer homeboy-gold homeboy-charcoal-black-text font-12">
      <div class="container">
        <div class="row">
          <div class="col l6 s12">

          </div>
          <div class="col l3 s12">

          </div>
          <div class="col l3 s12">
            <ul>
              <li>
                <router-link class="homeboy-charcoal-black-text underline"
                             to="/product-info"
                >
                  What is HomeBoy?
                </router-link>
              </li>
              <li>
                <router-link class="homeboy-charcoal-black-text underline"
                             to="/privacy-policy"
                >
                  Privacy Policy
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--<div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>-->
      <div class="footer-copyright">
        <div class="container">
          Powered by <a class="homeboy-charcoal-black-text underline" href="https://www.sdkdigilab.com">SDK DIGITAL LAB</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

    import M from "materialize-css";
export default {
  name: "HomeComponent",
  props: {
    msg: String
  },
    data()
    {
        return {
            instances: null
        };
    },
    created () {
      this.loadMenu();
    },
    mounted()
    {
        var elems = window.document.querySelectorAll('.parallax');
        this.instances = M.Parallax.init(elems, {});
    },
    methods : {
        loadMenu: function()
        {
            let _arrMenu = [
                {
                    title : "Find Supplier",
                    link: "/search/supplier",
                    icon: "search"
                },


            ];
            this.$store.dispatch('COMMIT_SET_MENU_LINKS', _arrMenu);
        },
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
