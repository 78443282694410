/**
 * Created by SDKDEV1 on 8/10/2020.
 */
import http from "../../http-common";
export default {
  state: {
    services: [],
    service: {},
    service_alias_loyal_to_local: "loyal-to-local",
    service_alias_food_credit: "food-credit",
    service_alias_third_party: "3rd-party"
  },

  getters: {},

  mutations: {
    SET_SERVICE(state, service) {
      // update subscriptions
      state.service = service;
    },
    SET_SERVICES_LIST(state, services) {
      // update subscriptions
      state.services = services;
    }
  },

  actions: {
    GET_SERVICES_REQUEST: ({ commit }) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/services/list",
          method: "POST",
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            //console.log("Subscriptions: " + JSON.stringify(resp.data.data));
            commit("SET_SERVICES_LIST", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    },

    GET_SERVICE_REQUEST: ({ commit }, service) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/services/view",
          method: "POST",
          data: {
            service: service
          },
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            // console.log("Supplier: " + JSON.stringify(resp.data.data));
            commit("SET_SERVICE", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    },

      COMMIT_SET_SERVICES_LIST:({commit},_value)=>
      {
          commit('SET_SERVICES_LIST',_value);
      },
  }
};
