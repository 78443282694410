<template xmlns:v-on="">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 l10 offset-l1">
            <div class="card col s12 vert-offset-top-1 padding-top-half">
                <div class="col s12 card-title padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-font">
                    <router-link class="black-text" to="/register">
                        <i class="material-icons">arrow_back</i>
                    </router-link>
                    <span>Business Registration</span>(Step {{step}}/{{maxStep}})
                </div>

                <div class="card-content col s12 padding-left-0 padding-right-0 padding-top-1 font-12">

                    <div class="col s12 no-padding">
                        <div class="col s12 no-padding">

                            <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-half">
                                <div class="col s12">

                                    <div class="col s12 no-padding" v-show="step==1">
                                        <div class="col s12">
                                            <input-component v-model="name" type="text" id="name" label="Business Name" required="required"></input-component>
                                        </div>
                                        <div class="col s12">
                                            <input-component id="description" type="text" v-model="description" label="Please describe your business in a few words." data-length="200"></input-component>
                                        </div>
                                        <div class="col s12 m6" v-if="existsSectors">
                                            <div class="row vert-offset-bottom-1 padding-right-half">
                                                <custom-select-component :options="sectors"  v-model="selected_sector" title="Type to select your business sector" :searchStart="false">
                                                </custom-select-component>
                                            </div>

                                        </div>

                                        <div class="col s12 m6" v-if="industriesExists">
                                            <div class="row vert-offset-bottom-1 padding-right-half">
                                                <custom-select-component id="industries" :options="industries" v-show="industriesExists" v-model="selected_industry" title="Type to select your Industry" :searchStart="false">
                                                </custom-select-component>
                                            </div>

                                        </div>

                                        <div class="col s12 vert-offset-top-1 no-padding">
                                            <div class="col s12 font-12 padding-bottom-half">
                                                Please provide <span class="font-bold">five (5) keyword/key-phrases</span> to help customers find you. <span class="font-bold">({{countChips}}/5)</span>
                                            </div>
                                            <div class="col s12">
                                                <custom-chip-component :data="chipsTags" v-on:chipData="chipData" :autocomplete="false">

                                                </custom-chip-component>
                                                <span class="font-10 blue-text text-darken-4 vert-offset-bottom-1">To confirm a Keyword, press Enter/Return/Go after you are done typing.</span>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col s12" v-show="step==2">
                                        <div class="col s12 vert-offset-bottom-half">
                                            <h6>Contact Details</h6>
                                        </div>
                                        <div class="col s12 m6">
                                            <input-component id="email" type="email" v-model="email" label="What is your business E-Mail Address?" required="required" autocomplete="email" data-length="150"></input-component>
                                        </div>
                                        <div class="col s12 m6">

                                            <input-component id="phone" type="tel" v-model="phone" label="What is your business Contact Number?" data-length="15"></input-component>
                                        </div>
                                    </div>

                                    <div class="col s12 vert-offset-bottom-1" v-show="step==3">
                                        <div class="col s12">
                                            <h6>Address</h6>
                                        </div>

                                        <div class="col s12 vert-offset-bottom-half">
                                            <div class="col s12 padding-left-0 padding-right-0 padding-top-1">
                                                <div class="col s12">
                                                    <custom-select-component :options="districts" v-model="selected_district_key" v-if="districtsExists" title="Type to select your District">

                                                    </custom-select-component>
                                                </div>

                                            </div>
                                            <div class="col s12 padding-left-0 padding-right-0 padding-top-1" v-show="citiesExists">
                                                <div class="col s12">
                                                    <custom-select-component :options="cities" v-model="selected_city_key"  title="Type to select your City">
                                                    </custom-select-component>
                                                </div>
                                            </div>
                                            <div class="col s12 padding-left-0 padding-right-0 padding-top-1" v-show="wardsExists">
                                                <div class="col s12">
                                                    <custom-select-component :options="wards" v-model="selected_ward_key"  title="Type to select your Location">

                                                    </custom-select-component>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col s12 padding-top-1">
                                            <input-component  id="physical_address" type="text" v-model="physical_address" label="Physical Address" data-length="200"></input-component>
                                        </div>
                                        <div class="col s12 no-padding vert-offset-top-half">
                                            <div class="col s12 vert-offset-bottom-half">
                                                <h6>GeoCoordinates</h6>
                                            </div>
                                            <div class="col s12 m6">
                                                <input-component  id="supplier_latitude" type="text" v-model="supplier_latitude" label="Latitude" helper="Optional" autocomplete="off" ref="supplier_latitude"></input-component>
                                            </div>
                                            <div class="col s12 m6">
                                                <input-component  id="supplier_longitude" type="text" v-model="supplier_longitude" label="Longitude" autocomplete="off" helper="Optional" ref="supplier_longitude"></input-component>
                                            </div>
                                            <div class="col s12 m4 center-align">
                                                <button v-show="canGeoLocate && geoSearch"
                                                        @click.prevent="locateMe"
                                                        class="btn btn-tiny font-10 homeboy-gold-text homeboy-charcoal-black text-darken-2 vert-offset-left-half"
                                                >
                                                    <i class="material-icons tiny left ">my_location</i>
                                                    Get Current Location
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col s12" v-show="step == 4">
                                        <div class="col s12 m6">
                                            <div class="col s12 no-padding grey-border border-lighten-2 border-bottom-1 vert-offset-bottom-1 vert-offset-top-half">
                                                <div class="col s12 no-padding">

                                                    <div class="col s12 no-padding vert-offset-top-1">
                                                        Do you have an office location?
                                                    </div>

                                                    <div class="col s12 m6 vert-offset-bottom-1 input-field">
                                                        <p>
                                                            <label @click="yesCreateSite">
                                                                <input type="radio" name="own-location"/>
                                                                <span class="bold homeboy-charcoal-black-text">Yes</span>
                                                            </label>
                                                        </p>
                                                    </div>

                                                    <div class="col s12 m6 vert-offset-bottom-1 input-field">
                                                        <p>
                                                            <label @click="noCreateSite">
                                                                <input type="radio" name="own-location"/>
                                                                <span class="bold homeboy-charcoal-black-text">No</span>
                                                            </label>
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col s12 m6">
                                            <div class="col s12 border-bottom-1 grey-border border-lighten-2 padding-top-half vert-offset-bottom-half">
                                                <div class="col s12 border-bottom-1 grey-border border-lighten-2 padding-bottom-half">
                                                    <span>How much discount will you like to give? (%)</span>
                                                </div>
                                                <div class="col s12 vert-offset-bottom-1 input-field">
                                                    <p v-for="(item, index) in discounts"
                                                       :key="index">
                                                        <label>
                                                            <input type="radio" :value="item"
                                                                   :key="index" v-model="discount"/>
                                                            <span v-html="item" class="bold homeboy-charcoal-black-text"></span>
                                                        </label>
                                                    </p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col s12" v-show="step ==5">
                                        <h5>Please confirm your information below</h5>

                                        <div class="col s12 border-bottom-1 grey-border vert-offset-top-half padding-bottom-half">
                                            <div class="col s12">
                                                <h6>Business Details</h6>
                                            </div>
                                            <div class="col s12 vert-offset-top-half">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    Name
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{name}}
                                                </div>
                                            </div>
                                            <div class="col s12 vert-offset-top-half" v-if="description != null">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    Description
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{description}}
                                                </div>
                                            </div>


                                            <div class="col s12 vert-offset-top-half" v-if="selected_sector_object != null">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    Organisation Sector
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{selected_sector_object.name}}
                                                </div>
                                            </div>

                                            <div class="col s12 vert-offset-top-half" v-if="selected_industry_object != null">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    Industry
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{selected_industry_object.name}}
                                                </div>
                                            </div>



                                            <div class="col s12 vert-offset-top-half ">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    Tags
                                                </div>

                                                <div class="col s8 m9 l10 font-10 no-padding">
                                        <span
                                                v-for="(_item,_index) in selectedChips"
                                                :key="_index"
                                                class="vert-offset-right-half underline lighten-2 grey-text text-darken-2"
                                        >
                                            {{ _item }}
                                        </span>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col s12 border-bottom-1 grey-border vert-offset-top-half padding-bottom-half">
                                            <div class="col s12">
                                                <h6>Contact Details</h6>
                                            </div>
                                            <div class="col s12 vert-offset-top-half">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    Email
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{email}}
                                                </div>
                                            </div>
                                            <div class="col s12 vert-offset-top-half">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    Contact Number
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{phone}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col s12 border-bottom-1 grey-border vert-offset-top-half padding-bottom-half">
                                            <div class="col s12">
                                                <h6>Location Details</h6>
                                            </div>

                                            <div class="col s12 vert-offset-top-half" v-if="selected_district_object != null">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    District
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{selected_district_object.name}}
                                                </div>
                                            </div>

                                            <div class="col s12 vert-offset-top-half" v-if="selected_city != null">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    City
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{selected_city.name}}
                                                </div>
                                            </div>

                                            <div class="col s12 vert-offset-top-half" v-if="selected_ward != null">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    Ward
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{selected_ward.name}}
                                                </div>
                                            </div>

                                            <div class="col s12 vert-offset-top-half" v-if="physical_address != null">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    Physical Address
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{physical_address}}
                                                </div>
                                            </div>

                                            <div class="col s12 vert-offset-top-half " v-if="supplier_latitude != null && supplier_longitude != null">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    GPS Coordinates
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{supplier_latitude}},{{supplier_longitude}}
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col s12 border-bottom-1 grey-border vert-offset-top-half padding-bottom-half">
                                            <div class="col s12">
                                                <h6>Other Information</h6>
                                            </div>
                                            <div class="col s12 vert-offset-top-half">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    Discount
                                                </div>
                                                <div class="col s8 m9 l10 black-text">
                                                    {{discount}}%
                                                </div>
                                            </div>

                                            <div class="col s12 vert-offset-top-half">
                                                <div class="col s4 m3 l2 grey-text text-darken-2">
                                                    Do you have an office location?
                                                </div>
                                                <div class="col s8 m9 l10 black-text" v-if="showSiteRegistrationForm">
                                                    Yes
                                                </div>
                                                <div class="col s8 m9 l10 black-text" v-else>
                                                    No
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>

                        </div>

                        <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-half">
                            <buttons-component v-on:validOrganisation="confirmCreateOrganisation" :buttonDisabled="showNextButton" :maxStep="maxStep" :step="step" :useSteps="true" ></buttons-component>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>


    import ButtonsComponent from "@/components/form/ButtonsComponent.vue"
    import InputComponent from "@/components/materialize/InputComponent.vue"
    import CustomSelectComponent from "@/components/materialize/CustomSelectComponent.vue"
    import CustomChipComponent from "@/components/materialize/CustomChipComponent.vue"


    import { bus } from "../../../main";
    import http from "../../../http-common"


    export default {
        name: "FormTwo",
        components:{
            ButtonsComponent,InputComponent,CustomSelectComponent,CustomChipComponent
        },
        data(){

            return{
                name:null,
                email:null,
                countries:[],
                picture:null,
                sectors: [],
                selected_sector: "",
                selected_country: "BW",
                phone: "",
                postal_address: "",
                physical_address: "",
                description: "",
                newOrganisationCreated:false,
                industries:[],
                selected_industry:null,
                selectedIndustries:null,
                selectedChips:null,
                chipsIndustries: [],
                chipsTags: [],
                discount:null,
                selected_site_id:null,
                selected_organisation_id: null,
                selected_organisation: null,
                cities:[],
                showSiteRegistrationForm:null,
                supplier_longitude:null,
                supplier_latitude:null,
                wards:[],
                districts:[],
                selected_ward_id:null,
                selected_city_id:null,
                selected_city:null,
                selected_city_key:null,
                selected_ward:null,
                selected_ward_key:null,
                selected_district:null,
                selected_district_key:null,
                created_site:false,
                tags:[],
                discounts:[],
                step:1,
                maxStep: 5,
                selected_industry_object:null,
                selected_sector_object:null,
                selected_district_object:null,
                near_me: true,
                showList: true,
                canGeoLocate: false,
                myLocation: null,
                image:null,

            }
        },
        created(){

            let $this = this;
            $this.getIndustries();
            /*this.getCountries();*/
            $this.getOrganisationSectors();
            $this.getDistricts();
            $this.getSupplierDiscounts();
            $this.$store.dispatch('SET_FOURTH_STEP',true);

            bus.$off('confirmAction');
            bus.$on("confirmAction", _data => {
                if (_data == "createOrganisation")
                {
                    console.log("ACTION: " + _data);
                    $this.registerBusiness();
                }
                /* if (_data == "createSupplier") {
                 console.log("ACTION: " + _data);
                 }*/
                $this.$store.dispatch("SET_ACTION", "");
            });

            bus.$off('goToNextStep');
            bus.$on("goToNextStep", function() {
                console.log("Go To Next Step");
                if($this.validateStep())
                {
                    if($this.step < $this.maxStep)
                    {
                        $this.step++;
                    }

                }
            });

            bus.$off('goToPreviousStep');
            bus.$on("goToPreviousStep", function() {
                console.log("Go To Previous Step");
                if($this.step > 1)
                {
                    $this.step--;
                }
            });
        },
        mounted()
        {
            this.checkGeoLocation();
        },
        methods:
            {
                checkGeoLocation: function()
                {
                    if (window.navigator.geolocation)
                    {
                        this.canGeoLocate = true;
                    } else {
                        console.log("Geolocation is not supported by this browser.");
                    }
                },
                async getGeoLocation()
                {
                    let $this = this;
                    $this.$store.dispatch('START_PROCESSING');
                    return new Promise((resolve,reject)=> {
                        if(!$this.canGeoLocate || !("geolocation" in window.navigator ))
                        {
                            $this.$store.dispatch('STOP_PROCESSING');
                            reject(new Error("GeoLocation is  not available"));
                        }
                        window.navigator.geolocation.getCurrentPosition(pos => {
                                $this.$store.dispatch('STOP_PROCESSING');
                                resolve(pos);
                            },
                            err => {
                                $this.$store.dispatch('STOP_PROCESSING');
                                reject(err);
                            },
                            {
                                enableHighAccuracy: true,
                                maximumAge: 30000,
                                timeout: 27000
                            });
                    });

                },
                async locateMe()
                {
                    let $this = this;
                    try
                    {

                        this.myLocation = await this.getGeoLocation();
                    }
                    catch(err)
                    {
                        if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError")
                        {
                            //required track is missing
                            console.log("Device Not Found");
                            $this.$store.dispatch('GET_MESSAGES', ["Device not Found", "If this error persists, try using a different browser"]);
                        }
                        else if (err.name == "NotReadableError" || err.name == "TrackStartError")
                        {
                            //webcam or mic are already in use
                            console.log("Device Already in use");
                            $this.$store.dispatch('GET_MESSAGES', ["Device already in use by another App", "If this error persists, try using a different browser"]);
                        }
                        else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError")
                        {
                            console.log("Constraints not satisfied");
                            //constraints can not be satisfied by avb. devices
                            $this.$store.dispatch('GET_MESSAGES', ["Device unable to satisfy required constraints.", "If this error persists, try using a different browser"]);
                        }
                        else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError")
                        {
                            console.log("Permission Denied");
                            //permission denied in browser
                            $this.$store.dispatch('GET_MESSAGES', ["Browser GeoLocation Access Permission Denied", "If this error persists, try using a different browser"]);
                        }
                        else if (err.name == "TypeError" || err.name == "TypeError")
                        {
                            console.log("Constraint Error");
                            //empty constraints object
                            $this.$store.dispatch('GET_MESSAGES', ["Constraint Error", "If this error persists, try using a different browser"]);
                        }
                        else
                        {
                            $this.$store.dispatch('GET_MESSAGES', [err.message, "If this error persists, try using a different browser"]);
                        }

                        console.log("Geo Error: " + JSON.stringify(err.message));
                    }
                },
                validateStep: function(_withoutPopUp)
                {
                    let _arrError = new Array();
                    let _returned = null;
                    if(this.step === 1)
                    {
                        //Name, Desciption, Industry, Organisation Type, Tags
                        if(typeof this.name === "undefined" || this.name === null || this.name.length <= 0)
                        {
                            _arrError.push("Invalid business Name");
                        }
                        else if(this.name.length < 2)
                        {
                            _arrError.push("Business Name too short, must be at least 2 characters");
                        }
                        else if(this.name.length > 100)
                        {
                            _arrError.push("Business Name too long, must be 100 characters maximum");
                        }

                        if(typeof this.description !== "undefined" && this.description !== null && this.description.length > 0)
                        {
                            if(this.description.length > 100)
                            {
                                _arrError.push("Business Description too long, must be 150 characters maximum");
                            }
                        }
                        if(typeof this.selected_industry === "undefined" || this.selected_industry === null || this.selected_industry.length <= 0)
                        {
                            _arrError.push("Invalid Industry, Please select a valid Industry.");
                        }
                        if(typeof this.selectedChips === "undefined" || this.selectedChips === null || this.selectedChips.length <= 0)
                        {
                            _arrError.push("Please provide a list of Keywords/Key-phrases to help customers find you easily.");
                            _arrError.push("To confirm a Keyword, press Enter after you are done typing.");
                            _arrError.push("A maximum of 5 keywords is allowed");
                        }
                        else if(this.selectedChips.length > 5)
                        {
                            _arrError.push("Only a maximum of 5 Keywords/Key-phrases allowed.");
                        }
                    }
                    else if(this.step === 2)
                    {
                        if(typeof this.email === "undefined" || this.email === null || this.email.length <= 0)
                        {
                            _arrError.push("Please provide your business's Contact Email");
                        }
                        else if(!this.email.isValidateEmail())
                        {
                            _arrError.push("Your contact Email address is invalid. Please provide a valid Email address");
                        }
                        else if(this.email.length > 100)
                        {
                            _arrError.push("Business Email too long, must be 100 characters maximum");
                        }

                        if(typeof this.phone === "undefined" || this.phone === null || this.phone.length <= 0)
                        {
                            _arrError.push("Please provide your business's Contact Number");
                        }
                        /*else if(!this.phone.isNumber())
                         {
                         _arrError.push("Your contact Email address is invalid. Please provide a valid Email address");
                         }*/
                        else if(this.phone.length > 12)
                        {
                            _arrError.push("Business Number too long");
                        }
                    }
                    else if(this.step === 3)
                    {
                        if(typeof this.selected_district_key === "undefined" || this.selected_district_key === null || this.selected_district_key.length <= 0)
                        {
                            _arrError.push("Please select your District");
                        }

                        if(typeof this.selected_city_key === "undefined" || this.selected_city_key === null || this.selected_city_key.length <= 0)
                        {
                            _arrError.push("Please select your City/Town");
                        }

                        if(typeof this.physical_address === "undefined" || this.physical_address === null || this.physical_address.length <= 0)
                        {
                            _arrError.push("Invalid business physical address");
                        }
                        else if(this.physical_address.length > 150)
                        {
                            _arrError.push("Business physical address too long, must be 150 characters maximum");
                        }

                        /*if(typeof this.postal_address !== "undefined" && this.postal_address !== null && this.postal_address.length > 0)
                        {
                            if(this.postal_address.length > 100)
                            {
                                _arrError.push("Business Description too long, must be 150 characters maximum");
                            }
                        }*/

                        if((typeof this.supplier_latitude !== "undefined" && this.supplier_latitude !== null && this.supplier_latitude.length > 0) || (typeof this.supplier_longitude !== "undefined" && this.supplier_longitude !== null && this.supplier_longitude.length > 0))
                        {
                            if((typeof this.supplier_latitude === "undefined" || this.supplier_latitude === null || this.supplier_latitude.length <= 0) || (typeof this.supplier_longitude === "undefined" || this.supplier_longitude === null || this.supplier_longitude.length <= 0))
                            {
                                _arrError.push("Invalid Latitude/Longitude GeoCoordinates");
                            }
                            else
                            {
                                this.supplier_latitude = parseFloat(this.supplier_latitude);
                                this.supplier_longitude = parseFloat(this.supplier_longitude);
                                if(isNaN(this.supplier_latitude) || isNaN(this.supplier_longitude))
                                {
                                    _arrError.push("Invalid Latitude/Longitude GeoCoordinates");
                                }

                                if(!isNaN(this.supplier_latitude) && (this.supplier_latitude < -90 || this.supplier_latitude > 90))
                                {
                                    _arrError.push("Invalid Latitude, please provide a valid Latitude GeCoordinate");
                                }
                                if(!isNaN(this.supplier_longitude) && (this.supplier_longitude < -180 || this.supplier_longitude > 180))
                                {
                                    _arrError.push("Invalid Longitude, please provide a valid Longitude GeCoordinate");
                                }
                            }

                        }
                    }
                    else if(this.step === 4)
                    {

                        //showSiteRegistrationForm
                        if(typeof this.showSiteRegistrationForm === "undefined" || this.showSiteRegistrationForm === null || [true,false].indexOf(this.showSiteRegistrationForm) < 0)
                        {
                            _arrError.push("You did not specify if you have an office Location.");
                        }

                        if(typeof this.discount === "undefined" || this.discount === null || this.discount.length <= 0)
                        {
                            _arrError.push("Please provide how much Discount you are willing to give out");
                        }
                        else
                        {
                            this.discount = parseInt(this.discount);
                            if(isNaN(this.discount) || this.discount < 10 || this.discount > 20)
                            {
                                _arrError.push("Please provide how much Discount you are willing to give out");
                            }
                        }
                    }
                    /* else if(this.step === 5)
                     {

                     }*/
                    else if(this.step === 5)
                    {
                        //Start Form Submission here

                    }
                    else
                    {
                        _arrError.push("Invalid Data Provided (Step"+this.step+")");
                    }

                    if(_arrError.length > 0)
                    {
                        if(typeof _withoutPopUp === "undefined" || _withoutPopUp === null)
                            this.$store.dispatch('GET_MESSAGES',_arrError);
                        return false;
                    }
                    return true;
                },
                getCountries: function() {
                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: 'registration/countries',
                            method: 'POST', timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            /* console.log("Response:" + JSON.stringify(resp));
                             console.log("data:" + JSON.stringify(resp.data));*/

                            this.countries = resp.data.data;


                            //this.$store.dispatch('SET_COUNTRIES_LIST',resp.data.data);
                            //this.$router.push('/organisation/view');
                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                            .catch(response => {

                                console.log("Response Error: "+JSON.stringify(response));
                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })
                },
                getOrganisationSectors: function() {
                    this.$store.dispatch("START_PROCESSING");

                    return new Promise(resolve => {
                        http({ url: "registration/organisation/sectors", method: "POST", timeout: 30000 })
                            .then(resp => {
                                this.$store.dispatch("STOP_PROCESSING");

                                //console.log("Response:" + JSON.stringify(resp));
                                //console.log("token:" + JSON.stringify(resp.data));

                                this.sectors = resp.data.data;

                                //console.log("Orgs: " + JSON.stringify(this.sectors));
                                // you have your token, now log in your user :)

                                resolve(resp);
                            })
                            .catch(response => {
                                this.$store.dispatch("STOP_PROCESSING");
                                this.$store.dispatch("GET_MESSAGES", response.data.messages);
                            });
                    });
                },
                uploadImage(e) {
                    this.image = e.target.files[0];
                    const photo = e.target.files[0];

                    const reader = new FileReader();
                    reader.readAsDataURL(photo);
                    reader.onload = e => {
                        this.picture = e.target.result;
                        console.log(this.picture);
                    };
                },
                chipData: function (data) {

                    this.selectedChips = new Array();
                    console.log("Data: " + JSON.stringify(data));

                    ///this.selectedChips = data;

                    for (let i = 0; i < data.length; i++) {

                        this.selectedChips.push(data[i].tag);

                    }

                    console.log("Selected Chips: " + JSON.stringify(this.selectedChips));

                    //return data;
                },
                chipIndustries: function (data) {

                    this.selectedIndustries = new Array();
                    console.log("Data: " + JSON.stringify(data));

                    ///this.selectedChips = data;

                    for (let i = 0; i < data.length; i++) {

                        this.selectedIndustries.push(data[i].tag);

                    }

                    console.log("Selected Chips: " + JSON.stringify(this.selectedIndustries));

                    //return data;
                },
                registerBusiness:function () {
                    //let image = this.$refs.logoImage.files[0];

                    this.$store.dispatch('START_PROCESSING');
                    /* let _formData = new FormData();
                     _formData.append('picture',this.$dataURIToBlob(this.picture));
                     _formData.append('name', this.name);
                     _formData.append('country', this.selected_country);
                     _formData.append('email', this.email);
                     _formData.append('msisdn', this.phone);
                     _formData.append('source',"HomeBoy");
                     _formData.append('description', this.description);
                     _formData.append('postal_address', this.postal_address);
                     _formData.append('physical_address', this.physical_address);
                     _formData.append('sector', this.selected_sector);
                     _formData.append('industries[]', this.selected_industry);

                     let _i;
                     for (_i = 0; _i < this.selectedChips.length; _i++) {
                     _formData.append('tags[]', this.selectedChips[_i]);
                     }

                     console.log("Picture: "+this.image);
                     console.log("Form Data: "+JSON.stringify(_formData));

                     */
                    return new Promise((resolve) => {
                        http({
                            url: 'registration/organisation/save',
                            method: 'POST',
                            data:{
                                name:this.name,
                                country:this.selected_country,
                                sector:this.selected_sector,
                                industries:[this.selected_industry],
                                email:this.email,
                                msisdn:this.phone,
                                description:this.description,
                                postal_address:this.postal_address,
                                physical_address:this.physical_address,
                                tags:this.selectedChips,
                                picture: this.picture,
                                source: this.$source
                            },
                            /* data: _formData,
                             header:{
                             'Content-Type' : 'multipart/form-data'
                             contentType:false,
                             processData:false,
                             },*/
                            cache:false,
                            timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            console.log("Response:" + JSON.stringify(resp));
                            console.log("data:" + JSON.stringify(resp.data));

                            this.selected_organisation = resp.data.data;
                            //this.$router.push('/third-step');

                            resolve(resp)
                        })
                            .catch(response => {

                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })
                },
                getIndustries(){
                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: '/organisations/industries/list/select',
                            method: 'POST',
                            timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            //console.log("Response:" + JSON.stringify(resp));
                            //console.log("data:" + JSON.stringify(resp.data));

                            this.industries = resp.data.data;
                            //

                            resolve(resp)
                        })
                            .catch(response => {

                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })
                },
                confirmCreateOrganisation: function() {
                    console.log("Event Triggered: createOrganisation");
                    this.$store.dispatch("SET_ACTION", "createOrganisation");
                },
                getSupplierDiscounts: function () {
                    this.$store.dispatch('START_PROCESSING');
                    return new Promise((resolve) => {
                        http({
                            url: "l2l/suppliers/registration/form",
                            method: 'POST',
                            timeout: 30000
                        })
                            .then(resp => {

                                this.$store.dispatch('STOP_PROCESSING');

                                console.log("Response:" + JSON.stringify(resp));
                                console.log("Data:" + JSON.stringify(resp.data));

                                this.discounts = resp.data.data.discounts;

                                // you have your token, now log in your user :)

                                resolve(resp)
                            })
                            .catch((response) => {
                                console.log("ErrResponse: " + JSON.stringify(response));
                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES', response.data.messages);
                                //reject(err)
                            })
                    })
                },
                getCities:function (district) {

                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: 'registration/cities',
                            data:{
                                district:district,
                                source: "HomeBoy"
                            },
                            method: 'POST', timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            //console.log("Response:" + JSON.stringify(resp));
                            //console.log("data:" + JSON.stringify(resp.data));

                            this.cities = resp.data.data;

                            console.log("Cities: " + JSON.stringify(resp.data.data));

                            //this.$refs.site_select.initializeSelect();

                            //this.$store.dispatch('SET_SITES_LIST',resp.data.data);
                            //this.$router.push('/organisation/view');
                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                            .catch(response => {

                                console.log("Response Error: "+JSON.stringify(response));
                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })


                },
                getDistricts:function () {

                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: 'registration/districts',
                            data:{
                                name:"Water",
                                source: "HomeBoy"
                            },
                            method: 'POST', timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            //console.log("Response:" + JSON.stringify(resp));
                            //console.log("data:" + JSON.stringify(resp.data));

                            this.districts = resp.data.data;

                            //console.log("Cities: " + JSON.stringify(resp.data.data));

                            //this.$refs.site_select.initializeSelect();

                            //this.$store.dispatch('SET_SITES_LIST',resp.data.data);
                            //this.$router.push('/organisation/view');
                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                            .catch(response => {

                                console.log("Response Error: "+JSON.stringify(response));
                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })


                },
                getWards:function (city) {

                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: '/registration/wards',
                            data:{
                                city:city,
                                source: "HomeBoy"
                            },
                            method: 'POST', timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            console.log("Response:" + JSON.stringify(resp));
                            console.log("data:" + JSON.stringify(resp.data));

                            this.wards = resp.data.data;

                            console.log("Wards: " + JSON.stringify(resp.data.data));

                            //this.$refs.site_select.initializeSelect();

                            //this.$store.dispatch('SET_SITES_LIST',resp.data.data);
                            //this.$router.push('/organisation/view');
                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                            .catch(response => {

                                console.log("Response Error: "+JSON.stringify(response));
                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })


                },

                createNewSupplier(){
                    console.log("+==================Creating new HomeBoy==================+");

                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: 'registration/supplier/save',
                            method: 'POST',
                            data:{
                                discount:this.discount,
                                name:this.name,
                                site:this.selected_site_id,
                                organisation:this.selected_organisation.id,
                                city:this.selected_city_id,
                                ward:(typeof this.selected_ward !=="undefined" && this.selected_ward !== null?this.selected_ward_id:null),
                                longitude:this.supplier_longitude,
                                latitude:this.supplier_latitude,
                                tags:this.tags,
                                msisdn:this.phone,
                                physical_address: this.physical_address,
                                email:this.email,
                                source: this.$source

                            },
                            timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            console.log("Response:" + JSON.stringify(resp));
                            console.log("data:" + JSON.stringify(resp.data));

                            this.$store.dispatch('GET_MESSAGES',[resp.data.data.name+" has been successfully created. You will receive an email from BrandBox, please follow the instruction to verify your account."]);
                            this.$store.dispatch('RESET_REGISTRATION');
                            this.$router.push('/');


                            //this.isRegisteredBusiness = 0;

                            //this.restart = true;

                            resolve(resp)
                        })
                            .catch(response => {

                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })
                },

                saveSite: function()
                {
                    console.log("City: "+this.selected_city.key);
                    console.log("ORG: "+this.selected_organisation.key);

                    //save site first
                    if(!this.showSiteRegistrationForm)
                    {
                        this.createNewSupplier();

                        return false;
                    }

                    console.log("+==================Creating new Site==================+");
                    //get city and ward and create a site then save supplier

                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: 'registration/site/save',
                            method: 'POST',
                            data:{
                                name:this.name,
                                organisation:this.selected_organisation.key,
                                city:this.selected_city.key,
                                ward:(typeof this.selected_ward !=="undefined" && this.selected_ward !== null?this.selected_ward.key:null),
                                longitude:this.supplier_longitude,
                                latitude:this.supplier_latitude,
                                postal_address:this.selected_organisation.postal_address,
                                physical_address:this.selected_organisation.physical_address,
                                source: this.$source
                            },
                            timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            console.log("Response:" + JSON.stringify(resp));
                            console.log("data:" + JSON.stringify(resp.data));

                            this.created_site = resp.data.data;

                            //this.$store.dispatch('GET_MESSAGES',[resp.data.data.name+" is successfully created."]);


                            resolve(resp)
                        })
                            .catch(response => {

                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })
                },

                yesCreateSite:function()
                {
                    this.showSiteRegistrationForm = true;
                },
                noCreateSite:function()
                {
                    this.showSiteRegistrationForm = false;
                },
                confirmCreateSupplier: function() {
                    console.log("Event Triggered: createSupplier");
                    this.$store.dispatch("SET_ACTION", "createSupplier");
                },


            },
        computed:{
            geoSearch: function()
            {
                return (this.near_me === true);
            },
            showNextButton:function () {

                return (this.validateStep(true) && this.step <= this.maxStep);
            },
            existsCountries: function() {
                return  typeof this.countries !== "undefined" && this.countries !== null && this.countries.length > 0;
            },
            existsSectors: function() {
                return typeof this.sectors !== "undefined" && this.sectors !== null && this.sectors.length > 0;
            },
            isPicture: function() {
                return this.picture != null;
            },

            industriesExists:function () {
                return typeof this.industries !== "undefined" && this.industries !== null && this.industries.length > 0;
            },

            wardsExists:function () {
                return typeof this.wards !== "undefined" && this.wards !== null && this.wards.length > 0;
            },
            citiesExists:function () {
                return typeof this.cities !== "undefined" && this.cities !== null && this.cities.length > 0;
            },
            districtsExists:function () {
                return typeof this.districts !== "undefined" && this.districts !== null && this.districts.length > 0;
            },
            countChips: function()
            {
                if(typeof this.selectedChips === "undefined" || this.selectedChips === null)
                    return 0;

                return this.selectedChips.length;
            }
        },
        watch: {
            'myLocation': function(_value)
            {
                if(typeof _value === "undefined" || _value === null)
                    return false;

                this.center = {
                    lat: parseFloat(_value.coords.latitude),
                    lng: parseFloat(_value.coords.longitude)
                };

                console.log("Center: "+JSON.stringify(this.center));


                this.supplier_latitude = this.center.lat.toFixed(6);
                this.supplier_longitude = this.center.lng.toFixed(6);

                let $this = this;
                setTimeout(function(){
                    $this.$refs.supplier_latitude.refreshInput();
                    $this.$refs.supplier_longitude.refreshInput();

                },500);
                //this.searchLocation(this.center.lat,this.center.lng);
            },
            'selected_city_key' : function(_key)
            {

                this.selected_city_id = null;
                this.selected_city = null;
                this.wards =[];
                if(typeof _key !== "undefined" && _key !== null){
                    this.getWards(_key);

                    console.log("SELECTED_City KEY: " +JSON.stringify(_key));
                    var _arrFound = this.cities.filter(function(item)
                    {
                        return (item.key == _key);
                    });
                    if(_arrFound.length>0)
                    {
                        console.log("Found City: "+_arrFound[0].id);
                        //this.selected_site_id = _arrFound[0].id;
                        this.selected_city = _arrFound[0];
                        this.selected_city_id = _arrFound[0].id;

                        //this.checkSiteSupplier(this.selected_site_id);

                    }
                    console.log("City ID:"+this.selected_city_id);
                }


            },
            'selected_ward_key' : function(_key)
            {
                this.selected_ward = null;
                this.selected_ward_id = null;

                if(typeof _key !== "undefined" && _key !== null){
                    console.log("SELECTED Ward KEY: " +JSON.stringify(_key));
                    var _arrFound = this.wards.filter(function(item)
                    {
                        return (item.key == _key);
                    });
                    if(_arrFound.length>0)
                    {
                        console.log("Found Ward: "+_arrFound[0].id);
                        //this.selected_site_id = _arrFound[0].id;
                        this.selected_ward = _arrFound[0];
                        this.selected_ward_id = _arrFound[0].id;

                        //this.checkSiteSupplier(this.selected_site_id);

                    }
                    console.log("Ward ID:"+this.selected_ward_id);
                }



            },
            'selected_district_key' : function(_key)
            {
                this.cities =[];

                this.selected_district_object = null;
                if(typeof _key !== "undefined" && _key !== null) {
                    this.getCities(_key);

                    var _arrFound = this.districts.filter(function(item)
                    {
                        return (item.key == _key);
                    });
                    if(_arrFound.length>0)
                    {
                        console.log("Found District: "+_arrFound[0].id);
                        this.selected_district_object = _arrFound[0];

                    }


                }
            },
            'selected_industry' : function(_key)
            {
                this.selected_industry_object = null;
                if(typeof _key !== "undefined" && _key !== null) {
                    var _arrFound = this.industries.filter(function(item)
                    {
                        return (item.key == _key);
                    });
                    if(_arrFound.length>0)
                    {
                        console.log("Found Industry: "+_arrFound[0]);
                        this.selected_industry_object = _arrFound[0];

                    }
                }
            },

            'selected_sector' : function(_key)
            {
                this.selected_sector_object = null;
                if(typeof _key !== "undefined" && _key !== null) {
                    var _arrFound = this.sectors.filter(function(item)
                    {
                        return (item.key == _key);
                    });
                    if(_arrFound.length>0)
                    {
                        console.log("Found sector: "+_arrFound[0].id);
                        this.selected_sector_object = _arrFound[0];

                    }
                }
            },
            'created_site':function () {
                console.log("created_site");
                if(typeof this.created_site !== "undefined" && this.created_site !== null)
                {
                    console.log("+==================Creating new HomeBoy via new Site==================+");
                    console.log("NEWLY Site: "+JSON.stringify(this.created_site));

                    this.selected_site_id = this.created_site.id;
                    this.createNewSupplier();
                }

            },
            'selected_organisation':function (_value) {

                if(typeof _value !== "undefined" && _value !== null)
                {
                    console.log("Found: "+_value.id);
                    this.selected_organisation_id = _value.id;

                    this.tags = new Array();

                    for(let k =0;k<_value.tags.data.length;k++)
                    {
                        this.tags.push(_value.tags.data[k].name);
                    }

                    this.saveSite();
                }
            }
        },




    };
</script>