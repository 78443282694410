<template>
  <div class="home">
    <HomeComponent msg="Welcome To HomeBoy"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeComponent from "@/components/HomeComponent.vue";

export default {
  name: "Home",
  components: {
    HomeComponent
  },
    created()
    {
        this.$setPageHeader();
    }

};
</script>
