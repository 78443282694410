import http from "../../http-common";
export default {
    state:{
        l2l_selected_organisation:{},
        l2l_suppliers:[],
        l2l_selected_supplier:{},
        l2l_supplier_subscription:{},
        l2l_countries:[],
    },

    mutations:{

        SET_L2L_ORGANISATION:(state,organisation)=>{
            state.l2l_selected_organisation = organisation;
        },

        SET_L2L_SUPPLIER:(state,supplier)=>{
            state.l2l_selected_supplier = supplier;
        },

        SET_L2L_SUPPLIERS_LIST:(state,suppliers)=>{
            state.l2l_suppliers = suppliers;
        },

        SET_SUPPLIER_SUBSCRIPTION:(state,subscription)=>
        {
            state.l2l_supplier_subscription = subscription
        },
        SET_L2L_COUNTRIES_LIST:(state,countries)=>{
            state.l2l_countries = countries;
        }

    },
    actions:{

        GET_L2L_SUPPLIERS_LIST:({commit},organisation)=>
        {
            commit('START_PROCESSING',{ root: true });

            return new Promise((resolve) => {
                http({
                    url: '/user/organisation/suppliers/all',
                    method: 'POST',
                    data:{
                        organisation:organisation
                    },
                    timeout: 30000

                }).then(resp => {
                    commit('STOP_PROCESSING',{ root: true });

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    // console.log("Supplier: " + JSON.stringify(resp.data.data));
                    commit('SET_L2L_SUPPLIERS_LIST',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING',{ root: true });
                        commit('SET_MESSAGES',response.data.messages,{ root: true });
                    })
            })
        },
        GET_L2L_ORGANISATION_REQUEST:({commit},organisation)=>
        {
            commit('START_PROCESSING',{ root: true });

            return new Promise((resolve) => {
                http({
                    url: 'user/organisations/view',
                    method: 'POST',
                    data:{
                        organisation:organisation,
                    },
                    timeout: 30000

                }).then(resp => {
                    commit('STOP_PROCESSING',{ root: true });

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    // console.log("Supplier: " + JSON.stringify(resp.data.data));
                    commit('SET_L2L_ORGANISATION',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING',{ root: true });
                        commit('SET_MESSAGES',response.data.messages,{ root: true });
                    })
            })
        },

        GET_L2L_SUPPLIER:({commit},supplier)=>
        {
            commit('START_PROCESSING',{ root: true });

            return new Promise((resolve) => {
                http({
                    url: '/user/supplier/view',
                    method: 'POST',
                    data:{
                        supplier:supplier
                    },
                    timeout: 30000

                }).then(resp => {
                    commit('STOP_PROCESSING',{ root: true });

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    // console.log("Supplier: " + JSON.stringify(resp.data.data));
                    commit('SET_L2L_SUPPLIER',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING',{ root: true });
                        commit('SET_MESSAGES',response.data.messages,{ root: true });
                    })
            })
        },

        GET_CURRENT_SUBSCRIPTION:({commit},supplier)=>
        {
            commit('START_PROCESSING',{ root: true });

            return new Promise((resolve) => {
                http({
                    url: 'l2l/subscriptions/current',
                    method: 'POST',
                    data:{
                        supplier:supplier
                    },
                    timeout: 30000

                }).then(resp => {
                    commit('STOP_PROCESSING',{ root: true });

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    // console.log("Supplier: " + JSON.stringify(resp.data.data));
                    commit('SET_SUPPLIER_SUBSCRIPTION',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING',{ root: true });
                        commit('SET_MESSAGES',response.data.messages,{ root: true });
                    })
            })
        },
        GET_L2L_COUNTRIES_REQUEST:({commit})=>
        {
            commit('START_PROCESSING',{root:true});

            return new Promise((resolve) => {
                http({
                    url: 'l2l/countries/list',
                    method: 'POST', timeout: 30000
                }).then(resp => {
                    commit('STOP_PROCESSING',{root:true});

                    //console.log("Response:" + JSON.stringify(resp));
                    //console.log("data:" + JSON.stringify(resp.data));

                    //this.organisation = resp.data.data;

                    //console.log("countries: " + JSON.stringify(resp.data.data));
                    commit('SET_L2L_COUNTRIES_LIST',resp.data.data);
                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        //console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING',{root:true});
                        commit('SET_MESSAGES',response.data.messages,{root:true});
                    })
            })
        },
    }
}