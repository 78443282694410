/**
 * Created by SDKDEV1 on 8/10/2020.
 */

export default {
    state: {
        registration_organisations: [],
        registration_organisation: {},
        business_email:"",
        isFourth:false,

    },

  getters: {},

    mutations: {
        SET_REG_ORGANISATION(state, organisation) {
            // update subscriptions
            state.registration_organisation = organisation;
        },
        SET_REG_ORGANISATION_LIST(state, organisations) {
            // update subscriptions
            state.registration_organisations = organisations;
        },
        SET_BUSINESS_EMAIL(state,email)
        {
          state.business_email = email;
        },
        RESET_FORM(state)
        {
            state.registration_organisations=[];
            state.registration_organisation = {};
            state.business_email = "";
            state.isFourth=false;
        },
        SET_FOURTH_STEP(state,value)
        {
            state.isFourth=value;

        }
    },

    actions: {
        SET_REG_ORGANISATION:({commit},organisation) =>{

            commit("SET_REG_ORGANISATION", organisation);
        },

        SET_REG_ORGANISATION_LIST:({commit},organisations) =>{

            commit("SET_REG_ORGANISATION_LIST", organisations);
        },

        SET_BUSINESS_EMAIL:({commit},email)=>
        {
            commit("SET_BUSINESS_EMAIL",email);
        },

        RESET_REGISTRATION:({commit})=>
        {
            commit("RESET_FORM");
        },

        SET_FOURTH_STEP:({commit},val)=>
        {
            commit("SET_FOURTH_STEP",val);
        }
    }
};
