<template>


<div class="input-field vert-offset-top-0 vert-offset-bottom-half">
  <i v-if="isIcon" class="material-icons prefix">{{icon}}</i>
  <input class="validate" :type="type" :id="id" :value='value' @input="$emit('input',$event.target.value)" :placeholder="placeholder" :required="required" autocomplete="off" />
  <label :for="id">{{label}}</label>
  <span class="helper-text left-align homeboy-gold-text text-darken-4 font-10" v-show="hasHelperText">{{helper}}</span>

</div>

</template>

<script>

  import "materialize-css/dist/js/materialize.min"
  //import $ from 'jquery'
    import M from "materialize-css";
  //import bus from "../../main.js";

    export default {
        name:"input-component",
        props: {
            value: {
                type: [String,Number],
                default: ""
            },
            label:
                {
                type:String,
            },
            id:
                {
                type:String,
            },
            type:
                {
                type:String
            },
            placeholder:
                {
                type:String
            },
            icon:
                {
                type:String,
                    default:null
            },
            required:{
                type:String
            },
            helper:
                {
                    type:String
                },
            min:{
                type:Number,
                default:0
            },
            max:{
                type:Number,
                default:100
            },
            step:{
                type:Number,
                default:1
            }


        },

        /**id:opt.id,key:opt.key
         * @description Component local variables
         * @return {Object} data
         * @return {undefined|FormSelect} data.instance
         */

        mounted() {
            this.initialiseInputs();
        },
        computed:{
            isIcon:function () {

                if(typeof this.icon === "undefined" || this.icon === null || this.icon =="")
                {

                    return false;
                }
                console.log("ICON: "+this.icon);

                console.log("ICON: "+this.id);

                return true;
            },
            hasHelperText:function () {
                return typeof this.helper !=="undefined" && this.helper !== null && this.helper !=="";
            }
        },
        methods:{

            refreshInput:function(){

                console.log("refreshInput function changed");
                M.updateTextFields();

            },
            initialiseInputs()
            {
                M.updateTextFields();
            }
        }


    };





</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  input:not([type]):focus:not([readonly]),
  input[type=text]:not(.browser-default):focus:not([readonly]),
  input[type=password]:not(.browser-default):focus:not([readonly]),
  input[type=email]:not(.browser-default):focus:not([readonly]),
  input[type=url]:not(.browser-default):focus:not([readonly]),
  input[type=time]:not(.browser-default):focus:not([readonly]),
  input[type=date]:not(.browser-default):focus:not([readonly]),
  input[type=datetime]:not(.browser-default):focus:not([readonly]),
  input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
  input[type=tel]:not(.browser-default):focus:not([readonly]),
  input[type=number]:not(.browser-default):focus:not([readonly]),
  input[type=search]:not(.browser-default):focus:not([readonly]),
  textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #fbc02d;
    -webkit-box-shadow: 0 1px 0 0 #fbc02d;
    box-shadow: 0 1px 0 0 #fbc02d;
  }

  input:not([type]):focus:not([readonly]) + label,
  input[type=text]:not(.browser-default):focus:not([readonly]) + label,
  input[type=password]:not(.browser-default):focus:not([readonly]) + label,
  input[type=email]:not(.browser-default):focus:not([readonly]) + label,
  input[type=url]:not(.browser-default):focus:not([readonly]) + label,
  input[type=time]:not(.browser-default):focus:not([readonly]) + label,
  input[type=date]:not(.browser-default):focus:not([readonly]) + label,
  input[type=datetime]:not(.browser-default):focus:not([readonly]) + label,
  input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label,
  input[type=tel]:not(.browser-default):focus:not([readonly]) + label,
  input[type=number]:not(.browser-default):focus:not([readonly]) + label,
  input[type=search]:not(.browser-default):focus:not([readonly]) + label,
  textarea.materialize-textarea:focus:not([readonly]) + label {
    color: #fbc02d;
  }
  .input-field .prefix.active {
    color: #fbc02d;
  }

  [type="checkbox"]:checked + span:not(.lever)::before {

    border-right: 2px solid #fbc02d;
    border-bottom: 2px solid #fbc02d;
  }
</style>
