<template xmlns:v-on="">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 l10 offset-l1">
            <div class="card col s12 vert-offset-top-1 padding-top-half">
                <div class="col s12 card-title padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black white-text homeboy-font align-middle">
                    <div class="col s12 no-padding">
                            HomeBoy Account Details (3/3)
                    </div>
                </div>

                <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">

                    <div class="col s12 no-padding">
                        <div class="col s12">
                            <div class="col s12 no-padding" v-if="!from_forth">
                                <div class="col s12 vert-offset-bottom-half no-padding">
                                    Please select the corresponding Business/Organisation
                                </div>
                                <div class="col s12 no-padding">
                                    <custom-select-component :options="organisations" v-model="selected_organisation_key" v-if="existsOrganisations" title="Type to select your Organisation">

                                    </custom-select-component>
                                </div>
                            </div>

                            <div class="col s12 no-padding" v-if="from_forth">
                                <div class="col s12 vert-offset-bottom-half no-padding">
                                  Organisation: {{selected_organisation.name}}
                                </div>
                            </div>

                        </div>

                        <div class="col s12 no-padding vert-offset-top-half"
                             v-show="selected_organisation_key"
                             id="new_supplier"
                        >

                            <form>
                                <div class="col s12 m6">
                                    <div class="col s12 no-padding grey-border border-lighten-2 border-bottom-1 vert-offset-top-half vert-offset-bottom-1">
                                        <div class="col s12 no-padding vert-offset-bottom-half">
                                            Basic Details
                                        </div>
                                    </div>

                                    <div class="col s12 no-padding">
                                        <div class="col s12">
                                            <input-component v-model="supplier_name" type="text" id="supplier_name" label="Enter your name" required="required"></input-component>
                                        </div>

                                    </div>

                                    <div class="col s12 no-padding">
                                        <div class="col s12">
                                            <input-component v-model="supplier_contact" type="text" id="supplier_contact" label="Enter your contact number" required="required" ref="supplierContact"></input-component>
                                        </div>
                                    </div>

                                    <div class="col s12 no-padding">
                                        <div class="col s12 padding-left-0 padding-right-0 padding-top-1">
                                            <div class="col s12">
                                                <custom-select-component :options="districts" v-model="selected_district_key" v-if="districtsExists" title="Type to select your District">

                                                </custom-select-component>
                                            </div>

                                        </div>
                                        <div class="col s12 padding-left-0 padding-right-0 padding-top-1" v-show="citiesExists">
                                            <div class="col s12">
                                                <custom-select-component :options="cities" v-model="selected_city_key"  title="Type to select your City">
                                                </custom-select-component>
                                            </div>
                                        </div>
                                        <div class="col s12 padding-left-0 padding-right-0 padding-top-1" v-show="wardsExists">
                                            <div class="col s12">
                                                <custom-select-component :options="wards" v-model="selected_ward_key"  title="Type to select your Location">

                                                </custom-select-component>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col s12 no-padding vert-offset-top-half">
                                        <div class="col s12 m6">
                                            <input-component  id="supplier_latitude" type="text" v-model="supplier_latitude" label="Latitude" helper="Optional" autocomplete="off" ref="supplier_latitude"></input-component>
                                        </div>
                                        <div class="col s12 m6">
                                            <input-component  id="supplier_longitude" type="text" v-model="supplier_longitude" label="Longitude" autocomplete="off" helper="Optional" ref="supplier_longitude"></input-component>
                                        </div>
                                        <div class="col s12 m4 center-align">
                                            <button v-show="canGeoLocate && geoSearch"
                                                    @click.prevent="locateMe"
                                                    class="btn btn-tiny font-10 homeboy-gold-text homeboy-charcoal-black text-darken-2 vert-offset-left-half"
                                            >
                                                <i class="material-icons tiny left ">my_location</i>
                                                Get Current Location
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col s12 m6">
                                    <div class="col s12 no-padding"
                                    >
                                        <div class="col s12 no-padding grey-border border-lighten-2 border-bottom-1 vert-offset-bottom-1 vert-offset-top-half">
                                            <div class="col s12 border-bottom-1 grey-border border-lighten-2 padding-bottom-half">
                                                Do you have an office location?
                                            </div>
                                            <div class="col s12 no-padding">
                                                <div class="col s12 m6 vert-offset-bottom-1 input-field">
                                                    <p>
                                                        <label @click="yesCreateSite">
                                                            <input type="radio" name="own-location"/>
                                                            <span class="bold homeboy-charcoal-black-text">Yes</span>
                                                        </label>
                                                    </p>
                                                </div>

                                                <div class="col s12 m6 vert-offset-bottom-1 input-field">
                                                    <p>
                                                        <label @click="noCreateSite">
                                                            <input type="radio" name="own-location"/>
                                                            <span class="bold homeboy-charcoal-black-text">No</span>
                                                        </label>
                                                    </p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="col s12 vert-offset-top-half" >
                                        <input-component  id="physical_address" type="text" v-model="physical_address" label="What is your Physical Address" data-length="200"></input-component>
                                    </div>
                                    <div class="col s12 border-bottom-1 grey-border border-lighten-2 padding-top-half vert-offset-bottom-half">
                                        <div class="col s12 border-bottom-1 grey-border border-lighten-2 padding-bottom-half">
                                            <span>How much discount will you like to give? (%)</span>
                                        </div>
                                        <div class="col s12 vert-offset-bottom-1 input-field">
                                            <p v-for="(item, index) in discounts"
                                               :key="index">
                                                <label>
                                                    <input type="radio" :value="item"
                                                           :key="index" v-model="discount"/>
                                                    <span v-html="item" class="bold homeboy-charcoal-black-text"></span>
                                                </label>
                                            </p>


                                        </div>
                                    </div>
                                </div>



                            </form>
                        </div>

                        <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-half">
                            <buttons-component v-on:validSupplier="confirmCreateSupplier" :buttonDisabled="showFinishButton"></buttons-component>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>


    import ButtonsComponent from "@/components/form/ButtonsComponent.vue"
    import CustomSelectComponent from "@/components/materialize/CustomSelectComponent.vue"
    import InputComponent from "@/components/materialize/InputComponent.vue"
    import http from "../../../http-common"
    import { bus } from "../../../main";

    import {mapState} from "vuex"

    export default {
        name: "FormTwo",
        components:{
            ButtonsComponent,InputComponent,CustomSelectComponent
        },
        data(){

            return{
                discount:null,
                supplier_name:null,
                selected_site_id:null,
                selected_organisation_key: null,
                selected_organisation: null,
                selected_organisation_id: null,
                cities:[],
                showSiteRegistrationForm:false,
                supplier_longitude:null,
                supplier_latitude:null,
                wards:[],
                districts:[],
                selected_ward_id:null,
                selected_city_id:null,
                selected_city:null,
                selected_city_key:null,
                selected_ward:null,
                selected_ward_key:null,
                selected_district:null,
                selected_district_key:null,
                created_site:false,
                tags:[],
                discounts:[],
                supplier_contact:"",
                physical_address: "",
                near_me: true,
                showList: true,
                canGeoLocate: false,
                myLocation: null,

            }
        },
        created(){
            //this.getCities();
            this.getDistricts();
            this.getSupplierDiscounts();

            bus.$off('confirmAction');


            bus.$on("confirmAction", _data => {

                if (_data == "createSupplier") {
                    console.log("ACTION: " + _data);
                    this.saveSite();
                }
                this.$store.dispatch("SET_ACTION", "");
            });

        },
        mounted(){

            this.checkSelectedOrganisation();
            this.checkGeoLocation();

        },
        methods:
            {
                checkGeoLocation: function()
                {
                    if (window.navigator.geolocation)
                    {
                        this.canGeoLocate = true;
                    } else {
                        console.log("Geolocation is not supported by this browser.");
                    }
                },
                async getGeoLocation()
                {
                    let $this = this;
                    $this.$store.dispatch('START_PROCESSING');
                    return new Promise((resolve,reject)=> {
                        if(!$this.canGeoLocate || !("geolocation" in window.navigator ))
                        {
                            $this.$store.dispatch('STOP_PROCESSING');
                            reject(new Error("GeoLocation is  not available"));
                        }
                        window.navigator.geolocation.getCurrentPosition(pos => {
                                $this.$store.dispatch('STOP_PROCESSING');
                                resolve(pos);
                            },
                            err => {
                                $this.$store.dispatch('STOP_PROCESSING');
                                reject(err);
                            },
                            {
                                enableHighAccuracy: true,
                                maximumAge: 30000,
                                timeout: 27000
                            });
                    });

                },
                async locateMe()
                {
                    let $this = this;
                    try
                    {

                        this.myLocation = await this.getGeoLocation();
                    }
                    catch(err)
                    {
                        if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError")
                        {
                            //required track is missing
                            console.log("Device Not Found");
                            $this.$store.dispatch('GET_MESSAGES', ["Device not Found"]);
                        }
                        else if (err.name == "NotReadableError" || err.name == "TrackStartError")
                        {
                            //webcam or mic are already in use
                            console.log("Device Already in use");
                            $this.$store.dispatch('GET_MESSAGES', ["Device already in use by another App"]);
                        }
                        else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError")
                        {
                            console.log("Constraints not satisfied");
                            //constraints can not be satisfied by avb. devices
                            $this.$store.dispatch('GET_MESSAGES', ["Device unable to satisfy required constraints."]);
                        }
                        else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError")
                        {
                            console.log("Permission Denied");
                            //permission denied in browser
                            $this.$store.dispatch('GET_MESSAGES', ["Browser GeoLocation Access Permission Denied"]);
                        }
                        else if (err.name == "TypeError" || err.name == "TypeError")
                        {
                            console.log("Constraint Error");
                            //empty constraints object
                            $this.$store.dispatch('GET_MESSAGES', ["Constraint Error"]);
                        }
                        else
                        {
                            $this.$store.dispatch('GET_MESSAGES', [err.message]);
                        }

                        console.log("Geo Error: " + JSON.stringify(err.message));
                    }
                },
                validateForm: function()
                {
                    let _arrError = new Array();
                    if(typeof this.selected_organisation === "undefined" || this.selected_organisation === null)
                    {
                        _arrError.push("Please select the relevant Business/Organisation");
                    }
                    if(typeof this.supplier_name === "undefined" || this.supplier_name === null || this.supplier_name.length <= 0)
                    {
                        _arrError.push("Invalid Business/Organisation Name");
                    }
                    else if(this.supplier_name.length < 2)
                    {
                        _arrError.push("Business/Organisation Name too short, must be at least 2 characters");
                    }
                    else if(this.supplier_name.length > 100)
                    {
                        _arrError.push("Business/Organisation Name too long, must be 100 characters maximum");
                    }
                    
                    if(typeof this.showSiteRegistrationForm === "undefined" || this.showSiteRegistrationForm === null || [true,false].indexOf(this.showSiteRegistrationForm) < 0)
                    {
                        _arrError.push("You did not specify if you have an office Location.");
                    }

                    if(typeof this.discount === "undefined" || this.discount === null || this.discount.length <= 0)
                    {
                        _arrError.push("Please provide how much Discount you are willing to give out");
                    }
                    else
                    {
                        this.discount = parseInt(this.discount);
                        if(isNaN(this.discount) || this.discount < 10 || this.discount > 20)
                        {
                            _arrError.push("Please provide how much Discount you are willing to give out");
                        }
                    }

                    if(typeof this.selected_district_key === "undefined" || this.selected_district_key === null || this.selected_district_key.length <= 0)
                    {
                        _arrError.push("Please select your District");
                    }

                    if(typeof this.selected_city_key === "undefined" || this.selected_city_key === null || this.selected_city_key.length <= 0)
                    {
                        _arrError.push("Please select your City/Town");
                    }

                    if(typeof this.physical_address === "undefined" || this.physical_address === null || this.physical_address.length <= 0)
                    {
                        _arrError.push("Invalid physical address");
                    }
                    else if(this.physical_address.length > 150)
                    {
                        _arrError.push("Physical address too long, must be 150 characters maximum");
                    }

                    if((typeof this.supplier_latitude !== "undefined" && this.supplier_latitude !== null && this.supplier_latitude.length > 0) || (typeof this.supplier_longitude !== "undefined" && this.supplier_longitude !== null && this.supplier_longitude.length > 0))
                    {
                        if((typeof this.supplier_latitude === "undefined" || this.supplier_latitude === null || this.supplier_latitude.length <= 0) || (typeof this.supplier_longitude === "undefined" || this.supplier_longitude === null || this.supplier_longitude.length <= 0))
                        {
                            _arrError.push("Invalid Latitude/Longitude GeoCoordinates");
                        }
                        else
                        {
                            this.supplier_latitude = parseFloat(this.supplier_latitude);
                            this.supplier_longitude = parseFloat(this.supplier_longitude);
                            if(isNaN(this.supplier_latitude) || isNaN(this.supplier_longitude))
                            {
                                _arrError.push("Invalid Latitude/Longitude GeoCoordinates");
                            }

                            if(!isNaN(this.supplier_latitude) && (this.supplier_latitude < -90 || this.supplier_latitude > 90))
                            {
                                _arrError.push("Invalid Latitude, please provide a valid Latitude GeCoordinate");
                            }
                            if(!isNaN(this.supplier_longitude) && (this.supplier_longitude < -180 || this.supplier_longitude > 180))
                            {
                                _arrError.push("Invalid Longitude, please provide a valid Longitude GeCoordinate");
                            }
                        }

                    }

                    if(typeof this.supplier_contact === "undefined" || this.supplier_contact === null || this.supplier_contact.length <= 0)
                    {
                        _arrError.push("Please provide your Contact Number");
                    }
                    else if(this.supplier_contact.length > 12)
                    {
                        _arrError.push("Contact Number too long");
                    }

                    if(_arrError.length > 0)
                    {
                        this.$store.dispatch('GET_MESSAGES',_arrError);
                        return false;
                    }

                    return true;
                },
                checkSelectedOrganisation:function(){
                    if(typeof this.selected_organisation !== "undefined" && this.selected_organisation !== null)
                    {
                        console.log("checkSelectedOrganisation");
                        if(this.selected_organisation_key !== this.selected_organisation.key)
                        {
                            this.selected_organisation_key = this.selected_organisation.key;
                        }
                    }
                },
                getSupplierDiscounts: function () {
                    this.$store.dispatch('START_PROCESSING');
                    return new Promise((resolve) => {
                        http({
                            url: "l2l/suppliers/registration/form",
                            method: 'POST',
                            timeout: 30000
                        })
                            .then(resp => {

                                this.$store.dispatch('STOP_PROCESSING');

                                console.log("Response:" + JSON.stringify(resp));
                                console.log("Data:" + JSON.stringify(resp.data));

                                this.discounts = resp.data.data.discounts;

                                // you have your token, now log in your user :)

                                resolve(resp)
                            })
                            .catch((response) => {
                                console.log("ErrResponse: " + JSON.stringify(response));
                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES', response.data.messages);
                                //reject(err)
                            })
                    })
                },

                getCities:function (district) {

                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: 'registration/cities',
                            data:{
                                district:district
                            },
                            method: 'POST', timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            console.log("Response:" + JSON.stringify(resp));
                            console.log("data:" + JSON.stringify(resp.data));

                            this.cities = resp.data.data;

                            console.log("Cities: " + JSON.stringify(resp.data.data));

                            //this.$refs.site_select.initializeSelect();

                            //this.$store.dispatch('SET_SITES_LIST',resp.data.data);
                            //this.$router.push('/organisation/view');
                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                            .catch(response => {

                                console.log("Response Error: "+JSON.stringify(response));
                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })


                },

                getDistricts:function () {

                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: 'registration/districts',
                            method: 'POST', timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            console.log("Response:" + JSON.stringify(resp));
                            console.log("data:" + JSON.stringify(resp.data));

                            this.districts = resp.data.data;

                            console.log("Cities: " + JSON.stringify(resp.data.data));

                            //this.$refs.site_select.initializeSelect();

                            //this.$store.dispatch('SET_SITES_LIST',resp.data.data);
                            //this.$router.push('/organisation/view');
                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                            .catch(response => {

                                console.log("Response Error: "+JSON.stringify(response));
                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })


                },


                getWards:function (city) {

                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: '/registration/wards',
                            data:{
                                city:city
                            },
                            method: 'POST', timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            console.log("Response:" + JSON.stringify(resp));
                            console.log("data:" + JSON.stringify(resp.data));

                            this.wards = resp.data.data;

                            console.log("Wards: " + JSON.stringify(resp.data.data));

                            //this.$refs.site_select.initializeSelect();

                            //this.$store.dispatch('SET_SITES_LIST',resp.data.data);
                            //this.$router.push('/organisation/view');
                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                            .catch(response => {

                                console.log("Response Error: "+JSON.stringify(response));
                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })


                },

                saveSite: function()
                {
                    console.log("City: "+this.selected_city.key);
                    console.log("ORG: "+this.selected_organisation.key);

                    //save site first
                    if(!this.showSiteRegistrationForm)
                    {
                        this.createNewSupplier();

                        return false;
                    }

                    console.log("+==================Creating new Site==================+");
                    //get city and ward and create a site then save supplier

                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: 'registration/site/save',
                            method: 'POST',
                            data:{
                                name:this.supplier_name,
                                organisation:this.selected_organisation.key,
                                city:this.selected_city.key,
                                ward:((typeof this.selected_ward !== "undefined" && this.selected_ward !== null)?this.selected_ward.key:null),
                                longitude:this.supplier_longitude,
                                latitude:this.supplier_latitude,
                                postal_address:this.selected_organisation.postal_address,
                                physical_address:this.selected_organisation.physical_address,
                            },
                            timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            console.log("Response:" + JSON.stringify(resp));
                            console.log("data:" + JSON.stringify(resp.data));

                            this.created_site = resp.data.data;

                            //this.$store.dispatch('GET_MESSAGES',[resp.data.data.name+" is successfully created."]);


                            resolve(resp)
                        })
                            .catch(response => {

                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })
                },

                createNewSupplier(){
                    console.log("+==================Creating new HomeBoy==================+");

                    this.$store.dispatch('START_PROCESSING');

                    return new Promise((resolve) => {
                        http({
                            url: 'registration/supplier/save',
                            method: 'POST',
                            data:{
                                discount:this.discount,
                                name:this.supplier_name+" HomeBoy",
                                site:this.selected_site_id,
                                organisation:this.selected_organisation.id,
                                city:this.selected_city_id,
                                ward:this.selected_ward_id,
                                longitude:this.supplier_longitude,
                                latitude:this.supplier_latitude,
                                tags:this.tags,
                                msisdn:this.supplier_contact,
                                physical_address: this.physical_address

                            },
                            timeout: 30000
                        }).then(resp => {
                            this.$store.dispatch('STOP_PROCESSING');

                            console.log("Response:" + JSON.stringify(resp));
                            console.log("data:" + JSON.stringify(resp.data));

                            this.$store.dispatch('GET_MESSAGES',[resp.data.data.name+" is successfully created."]);
                            this.$store.dispatch('RESET_REGISTRATION');
                            this.$router.push('/');


                            //this.isRegisteredBusiness = 0;

                            //this.restart = true;

                            resolve(resp)
                        })
                            .catch(response => {

                                this.$store.dispatch('STOP_PROCESSING');
                                this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            })
                    })
                },


                yesCreateSite:function()
                {
                    this.showSiteRegistrationForm = true;
                },
                noCreateSite:function()
                {
                    this.showSiteRegistrationForm = false;
                },
                confirmCreateSupplier: function() {
                    if(this.validateForm())
                    {
                        console.log("Event Triggered: createSupplier");
                        this.$store.dispatch("SET_ACTION", "createSupplier");
                    }
                    
                },
            },
        computed:{

            geoSearch: function()
            {
                return (this.near_me === true);
            },
            ...mapState({
                organisations:state=>state.registration.registration_organisations,
                from_forth:state=>state.registration.isFourth,
             }),
            existsOrganisations:function () {
                return typeof this.organisations !== "undefined" && this.organisations !== null && this.organisations.length > 0;
            },
            showFinishButton:function () {
                 console.log("show finish button: "+(this.selected_city_id !== null  && this.selected_organisation !== null && this.supplier_name !== null && this.discount !== null));
                return this.selected_city_id !== null  && this.selected_organisation !== null && this.supplier_name !== null && this.discount !== null;
            },
            wardsExists:function () {
                return typeof this.wards !== "undefined" && this.wards !== null && this.wards.length > 0;
            },
            citiesExists:function () {
                return typeof this.cities !== "undefined" && this.cities !== null && this.cities.length > 0;
            },
            districtsExists:function () {
                return typeof this.districts !== "undefined" && this.districts !== null && this.districts.length > 0;
            },

        },
        watch: {
            'myLocation': function(_value)
            {
                if(typeof _value === "undefined" || _value === null)
                    return false;

                this.center = {
                    lat: parseFloat(_value.coords.latitude),
                    lng: parseFloat(_value.coords.longitude)
                };

                console.log("Center: "+JSON.stringify(this.center));


                this.supplier_latitude = this.center.lat.toFixed(6);
                this.supplier_longitude = this.center.lng.toFixed(6);

                let $this = this;
                setTimeout(function(){
                    $this.$refs.supplier_latitude.refreshInput();
                    $this.$refs.supplier_longitude.refreshInput();

                },500);
                //this.searchLocation(this.center.lat,this.center.lng);
            },
            'selected_organisation_key' : function(_key)
            {
                if(typeof _key !== "undefined" && _key !== null){
                    console.log("SELECTED_ORG: " +JSON.stringify(_key));
                    if(typeof this.organisations !== "undefined" && this.organisations !== null && this.organisations.length > 0)
                    {
                        var _arrFound = this.organisations.filter(function(item)
                        {
                            return (item.key == _key);
                        });
                        if(_arrFound.length>0)
                        {
                            this.selected_organisation = _arrFound[0];
                            console.log("Found: "+this.selected_organisation.id);
                            this.selected_organisation_id = this.selected_organisation.id;
                            /*this.$store.dispatch('SET_REG_ORGANISATION', this.selected_organisation);*/

                            this.supplier_contact = this.selected_organisation.contact.number;
                            this.physical_address = this.selected_organisation.address.physical;


                            this.tags = new Array();

                            for(let k =0;k<this.selected_organisation.tags.data.length;k++)
                            {
                                this.tags.push(this.selected_organisation.tags.data[k].name);
                            }

                        }
                    }
                    else
                    {
                        if(typeof this.selected_organisation !== "undefined" && this.selected_organisation !== null)
                        {
                            console.log("Found: "+this.selected_organisation.id);
                            this.selected_organisation_id = this.selected_organisation.id;

                            this.supplier_contact = this.selected_organisation.contact.number;


                            this.tags = new Array();

                            for(let k =0;k<this.selected_organisation.tags.data.length;k++)
                            {
                                this.tags.push(this.selected_organisation.tags.data[k].name);
                            }
                        }
                    }


                    var $this = this;
                    setTimeout(function(){

                        $this.$refs.supplierContact.refreshInput();

                    },500);
                    console.log("ID:"+this.selected_organisation_id);
                }

            },
            'selected_city_key' : function(_key)
            {

                this.selected_city_id = null;
                this.selected_city = null;
                this.wards =[];
                if(typeof _key !== "undefined" && _key !== null && _key.length > 0){
                    this.getWards(_key);

                    console.log("SELECTED_City KEY: " +JSON.stringify(_key));
                    var _arrFound = this.cities.filter(function(item)
                    {
                        return (item.key == _key);
                    });
                    if(_arrFound.length>0)
                    {
                        console.log("Found City: "+_arrFound[0].id);
                        //this.selected_site_id = _arrFound[0].id;
                        this.selected_city = _arrFound[0];
                        this.selected_city_id = _arrFound[0].id;

                        //this.checkSiteSupplier(this.selected_site_id);

                    }
                    console.log("City ID:"+this.selected_city_id);
                }


            },
            'selected_ward_key' : function(_key)
            {
                this.selected_ward = null;
                this.selected_ward_id = null;

                if(typeof _key !== "undefined" && _key !== null && _key.length > 0){
                    console.log("SELECTED Ward KEY: " +JSON.stringify(_key));
                    var _arrFound = this.wards.filter(function(item)
                    {
                        return (item.key == _key);
                    });
                    if(_arrFound.length>0)
                    {
                        console.log("Found Ward: "+_arrFound[0].id);
                        //this.selected_site_id = _arrFound[0].id;
                        this.selected_ward = _arrFound[0];
                        this.selected_ward_id = _arrFound[0].id;

                        //this.checkSiteSupplier(this.selected_site_id);

                    }
                    console.log("Ward ID:"+this.selected_ward_id);
                }



            },
            'selected_district_key' : function(_key)
            {
                this.cities =[];

                if(typeof _key !== "undefined" && _key !== null && _key.length > 0) {
                    this.getCities(_key);
                }
            },
            'created_site':function () {
                console.log("created_site");
                if(typeof this.created_site !== "undefined" && this.created_site !== null)
                {
                    console.log("+==================Creating new HomeBoy via new Site==================+");
                    console.log("NEWLY Site: "+JSON.stringify(this.created_site));

                    this.selected_site_id = this.created_site.id;
                    this.createNewSupplier();
                }

            },


        },

    };
</script>