<template>

    <div class="chips chips-autocomplete vert-offset-top-0 vert-offset-bottom-half">
    </div>


</template>

<script>
    import "materialize-css/dist/js/materialize.min";
    import M from "materialize-css";
    export default {
        name: "custom-chip-component",
        /**
         * @description Component local variables
         * @return {Object} data
         * @return {undefined|FormSelect} data.instance
         */
        data() {
            return {
                instance: undefined,

            };
        },

        props:{
            placeholder:{
                type:String,
                default:"Enter a tag",
            },
            secondaryPlaceholder:{
                type:String,
                default:"+tag",
            },
            limit:{
                type:Number,
                default:5
            },
            data:{
                type:Array,
                default:[]
            },
            addExtraChip:{
                type:Boolean,
                default:true
            },
            autocomplete:{
                type:Boolean,
                default:false
            },

        },
        methods: {
            initializeChip()
            {
                //let extraChip = this.addExtraChip
                let autoOptions = {};
                if(typeof this.data !== "undefined" && this.data !== null && this.data.length > 0)
                {
                    for(let i = 0;i<this.data.length;i++)
                    {
                        autoOptions[this.data[i].name] = null;
                    }
                }


                let myComponent =  this;
                let options = {
                    placeholder: this.placeholder,
                    secondaryPlaceholder: this.secondaryPlaceholder,
                    limit:this.limit,

                    onChipAdd: function(e, chip) {
                        var $this = this;

                        if(!$this.addExtraChip)
                        {
                            if($this.autocomplete)
                            {
                                $this.chipsData.forEach(function(e, index) {
                                    if(!(e.tag in autoOptions))
                                        $this.deleteChip(index);

                                });
                            }

                            myComponent.$emit('chipData',$this.chipsData);

                        }

                        //myComponent.selectedChips = this.chipsData;
                       // console.log("Selected Chips:"+JSON.stringify(myComponent.selectedChips))

                    },
                    onChipDelete: function(e, chip) {
                        var $this = this;
                        myComponent.$emit('chipData',$this.chipsData);


                    }
                };
                if(this.autocomplete)
                {
                    options.autocompleteOptions = {
                        data:autoOptions,
                    };
                }

                return M.Chips.init(this.$el, options);
            },
        },
        mounted() {
            console.log("component mounted Chip");
            this.instance = this.initializeChip();
            console.log(this.instance);
        },
        created() {
            console.log("component created");
        },
        watch: {
            'data': function()
            {
                this.instance = this.initializeChip();
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .chips .input {
        width: 150px !important;
    }
</style>