/**
 * Created by SDKDEV1 on 8/10/2020.
 */

import http from "../../http-common";
export default {
  state: {
    suppliers: [],
    supplier: {},
    organisationSuppliers: [],
    organisationAllSuppliers: [],
    timeout: 30000
  },

  getters: {},

  mutations: {
    SET_SUPPLIER(state, supplier) {
      // update subscriptions
      state.supplier = supplier;
    },
    SET_SUPPLIERS_LIST(state, suppliers) {
      // update subscriptions
      state.suppliers = suppliers;
    },
    SET_ORGANISATION_SUPPLIERS_LIST(state, suppliers) {
      // update subscriptions
      state.organisationAllSuppliers = suppliers;
    },
    SET_ORGANISATION_SUPPLIERS(state, suppliers) {
      // update subscriptions
      state.organisationSuppliers = suppliers;
    }
  },

  actions: {
    GET_SUPPLIERS_REQUEST: ({ commit }) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/subscriptions/list",
          method: "POST",
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            //console.log("Subscriptions: " + JSON.stringify(resp.data.data));
            commit("SET_SUPPLIERS_LIST", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    },

    GET_ORGANISATION_SUPPLIERS_REQUEST: ({ commit }, organisation) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/organisation/suppliers/list",
          method: "POST",
          data: {
            organisation: organisation
          },
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            //console.log("Subscriptions: " + JSON.stringify(resp.data.data));
            commit("SET_ORGANISATION_SUPPLIERS_LIST", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    },
    GET_ORGANISATION_SUPPLIERS_ALL_REQUEST: ({ commit }, organisation) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/organisation/suppliers/all",
          method: "POST",
          data: {
            organsation: organisation
          },
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            //console.log("Subscriptions: " + JSON.stringify(resp.data.data));
            commit("SET_ORGANISATION_SUPPLIERS", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    },

    GET_SUPPLIER_REQUEST: ({ commit }, supplier) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/suppliers/view",
          method: "POST",
          data: {
            supplier: supplier
          },
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            // console.log("Supplier: " + JSON.stringify(resp.data.data));
            commit("SET_SUPPLIER", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    },

    FIND_SUPPLIER_REQUEST: ({ commit }, supplier) => {
      commit("START_PROCESSING", { root: true });

      return new Promise(resolve => {
        http({
          url: "/suppliers/find",
          method: "POST",
          data: {
            supplier: supplier
          },
          timeout: 30000
        })
          .then(resp => {
            commit("STOP_PROCESSING", { root: true });

            console.log("Response:" + JSON.stringify(resp));
            console.log("data:" + JSON.stringify(resp.data));

            //this.organisation = resp.data.data;

            // console.log("Supplier: " + JSON.stringify(resp.data.data));
            commit("SET_SUPPLIER", resp.data.data);
            //this.$router.push('/organisation/view');
            // you have your token, now log in your user :)

            resolve(resp);
          })
          .catch(response => {
            console.log("Response Error: " + JSON.stringify(response));
            commit("STOP_PROCESSING", { root: true });
            commit("SET_MESSAGES", response.data.messages, { root: true });
          });
      });
    }
  }
};
