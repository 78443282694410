<template>
</template>

<script>
import "materialize-css/dist/js/materialize.min";
import M from "materialize-css";

export default {
  name: "tooltip-component",

  /**
   * @description Component local variables
   * @return {Object} data
   * @return {undefined|FormSelect} data.instance
   */
  data() {
    return {
      instance: undefined
    };
  },

  methods: {
    /**
     * @description Initialize a new Materialize select component
     * @param {Object} options
     * @return {FormSelect}
     * @see https://materializecss.com/select.html#options
     */
    initialize(options = {}) {
        let elems = document.querySelectorAll('.tooltipped');
        return M.Tooltip.init(elems, options);
    },

  },
  mounted() {
    this.instance = this.initialize();
  },
  watch: {},

  destroyed() {
    this.instance.destroy();
    //this.$el.removeEventListener('change', this.changeHandler)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
