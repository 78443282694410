/**
 * Created by SDKDEV1 on 8/10/2020.
 */

import http from "../../http-common"
export default {
    state: {
        organisation_user:{},
        user_roles:[],
        user_organisations_list:[]
    },


    getters:{
        user_organisations:function(state){
            let roles = state.user_roles;
            let user_orgs = [];

            for(let i = 0; i<roles.length;i++)
            {
                user_orgs.push(roles[i].organisation.data);
            }

            console.log("ORGANISATION ARR: "+JSON.stringify(user_orgs));
            return user_orgs;
        }
    },
    mutations: {

        SET_ORGANISATION_USER:(state,user)=>
        {
            state.organisation_user = user;
        },

        SET_USER_ORGANISATION_LIST:(state,organisations)=>
        {
            state.user_organisations_list = organisations;
        },

        SET_USER_ROLES:(state,roles)=>
        {
            state.user_roles = roles;
        },


    },

    actions: {
        USER_AUTH_REQUEST:({commit},user)=>
        {
            commit('START_PROCESSING',{ root: true });

            return new Promise((resolve) => {
                http({
                    url: '/organisations/login',
                    method: 'POST',
                    data:user,
                    timeout: 30000,
                    source: "HomeBoy"

                }).then(resp => {
                    commit('STOP_PROCESSING',{ root: true });

                    console.log("Response:" + JSON.stringify(resp));
                    console.log("data:" + JSON.stringify(resp.data));
                    const token = resp.data.data.token;
                    localStorage.setItem('token', token);
                    //this.organisation = resp.data.data;
                    http.defaults.headers.common['Authorization'] ="Bearer "+ token;

                    //console.log("Subscriptions: " + JSON.stringify(resp.data.data));
                    commit('SET_ORGANISATION_USER',resp.data.data.user_id);

                    commit('SET_USER_ROLES',resp.data.data.roles);
                    commit('AUTH_SUCCESS', token,{root:true});

                    //this.$router.push('/organisation/view');
                    // you have your token, now log in your user :)

                    resolve(resp)
                })
                    .catch(response => {

                        console.log("Response Error: "+JSON.stringify(response));
                        commit('STOP_PROCESSING',{ root: true });
                        commit('SET_MESSAGES',response.data.messages,{ root: true });
                    })
            })
        },

    }
}