<template>
  <div class="home">
    <PrivacyPolicyComponent msg="Welcome To HomeBoy"/>
  </div>
</template>

<script>
// @ is an alias to /src
import PrivacyPolicyComponent from "@/components/PrivacyPolicyComponent.vue";

export default {
  name: "Home",
  components: {
    PrivacyPolicyComponent
  },
    mounted()
    {
        this.$setPageHeader();
    }

};
</script>
