<template>
  <div class="row">

    <div class="container">
    <div class="section">

      <div class="row">
        <div class="card col s12">
          <div class="col s12 center">
            <h4 class="homeboy-font homeboy-charcoal-black-text">Privacy Policy</h4>
            <p class="left-align light font-14">
              HomeBoy Botswana takes your privacy very seriously.
            </p>

            <div class="card col s12">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="center homeboy-font homeboy-charcoal-black-text">Information Collection</h5>

                  <p class="left-align light">
                    We will not use the information collected from your interactions with BrandbBox in any way, shape, or form. In addition, any other material you provide us (including images, email addresses, etc.) will be held in the strict confidence.
                  </p>
                  <p class="left-align light">
                    In addition, we do not collect personally identifiable information about you except when you specifically provide this information on a voluntary basis. We will make every effort to ensure that whatever information you provide will be maintained in a secure environment.
                  </p>
                  <p class="left-align light">
                    However, even if you opt out of receiving any communications from HomeBoy, we reserve the right to contact you regarding your account status or any other matter that might affect our service to you and/or our records on you.
                  </p>
                </div>
              </div>
            </div>

            <div class="card col s12">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="center homeboy-font homeboy-charcoal-black-text">Information Use</h5>

                  <p class="left-align light">
                    HomeBoy reserves the right to perform statistical analyses of user behavior and characteristics. We do this in order to measure interest in and use of the various areas of the application/website.
                  </p>
                  <p class="left-align light">
                    HomeBoy collects IP addresses for system administration and record keeping. Your IP address is automatically assigned to your computer when you use the World Wide Web. Our servers record incoming IP addresses. The IP addresses are analyzed only in aggregate; no connection is made between you and your computer's IP address. By tracking IP addresses, we can determine which sites refer the most people to HomeBoy. (Think of an IP address like your zip code; it tells us in general terms where you're from.)
                  </p>
                </div>
              </div>
            </div>



            <div class="card col s12">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="center homeboy-font homeboy-charcoal-black-text">Cookies</h5>

                  <p class="left-align light">
                    "Cookies" are small text files a website can use to recognize repeat users. HomeBoy uses cookies to recognize visitors and more quickly provide personalized content or grant you unimpeded access to the website.
                  </p>

                  <p class="left-align light">
                    Information gathered through cookies also helps us measure use of our website. Cookie data allow us to track usage behavior and compile data that we can use to improve the site. This data will be used in aggregate form; no specific users will be tracked.
                  </p>
                  <p class="left-align light">
                    Generally, cookies work by assigning a unique number to the user that has no meaning outside of the Web site that he or she is visiting. You can easily turn off cookies. Most browsers have a feature that allows the user to refuse cookies or issues a warning when cookies are being sent. However, our site will not function properly without cookies. Enabling cookies ensures a smooth, efficient visit to our website.
                  </p>
                </div>
              </div>
            </div>

            <div class="card col s12">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="center homeboy-font homeboy-charcoal-black-text">Opting Out</h5>

                  <p class="left-align light">
                    Upon request, HomeBoy will allow any user to opt out of our services. Also, upon your request, HomeBoy will delete you and your personal information from our database; however, it may be impossible to delete all of your information without some residual data because of backups and records of deletions.
                  </p>
                  <p class="left-align light">
                    Should you wish to opt out of any HomeBoy services, please contact <a href="mailto:admin@homeboybw.com" class="underline black-text">admin@homeboybw.com</a>
                  </p>
                </div>
              </div>
            </div>

            <div class="card col s12">
              <div class="col s12 no-padding">
                <div class="icon-block">
                  <h5 class="center homeboy-font homeboy-charcoal-black-text">General Security Policy</h5>
                  <p class="left-align light">
                    HomeBoy is aware of your privacy concerns and strives to collect only as much data as is required to make your HomeBoy experience as efficient and satisfying as possible, in the most unobtrusive manner as possible.
                  </p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>
  </div>



    <footer class="page-footer homeboy-gold homeboy-charcoal-black-text font-12">
      <div class="container">
        <div class="row">
          <div class="col l6 s12">

          </div>
          <div class="col l3 s12">

          </div>
          <div class="col l3 s12">
            <ul>
              <li>
                <router-link class="homeboy-charcoal-black-text underline"
                             to="/"
                >
                  Home
                </router-link>
              </li>
              <li>
                <router-link class="homeboy-charcoal-black-text underline"
                             to="/product-info"
                >
                  What is HomeBoy?
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
     <div class="footer-copyright">
        <div class="container">
          Powered by <a class="homeboy-charcoal-black-text underline" href="https://www.sdkdigilab.com">SDK DIGITAL LAB</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

    import M from "materialize-css";
export default {
  name: "PrivacyPolicyComponent",
  props: {
    msg: String
  },
    data()
    {
        return {
            instances: null
        };
    },
    created () {
      this.loadMenu();
    },
    mounted()
    {
        var elems = window.document.querySelectorAll('.parallax');
        this.instances = M.Parallax.init(elems, {});
    },
    methods : {
        loadMenu: function()
        {
            let _arrMenu = [
                {
                    title : "Find Supplier",
                    link: "/search/supplier",
                    icon: "search"
                },


            ];
            this.$store.dispatch('COMMIT_SET_MENU_LINKS', _arrMenu);
        },
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
